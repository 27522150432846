import React, { useContext, useState } from "react";
import styles from "./Preview.module.less";
import { Typography, Space, Avatar } from "antd";
import { AcsData } from "../../../App";
const { Text } = Typography;

// preview component for design settings which are display live design change and show the looks of the switcher
const Preview = () => {
  const [hover, setHover] = useState(false);
  const { settingData, globalState } = useContext(AcsData);

  const {
    flagStyle,
    displayFlag,
    borderLook,
    borderLineColor,
    textColor,
    textHoverColor,
    backgroundColor,
    backgroundHoverColor,
  } = settingData;

  // console.log("backG", borderLineColor);

  let previewStyle;
  let previewTextStyles;
  previewStyle = {
    background: backgroundColor,
  };
  previewTextStyles = {
    color: textColor,
  };
  if (hover) {
    previewStyle = {
      ...previewStyle,
      background: backgroundHoverColor,
    };
    previewTextStyles = {
      ...previewTextStyles,
      color: textHoverColor,
    };
  }
  if (borderLook) {
    previewStyle = {
      ...previewStyle,
      border: `2px solid ${
        borderLineColor === "inherit" ? "#ffffff" : borderLineColor
      }`,
      // border: "1px solid black",
    };
  }

  // if (!displayPrice || currencyFormat === "without") {
  //   previewTextStyles = {
  //     ...previewTextStyles,
  //     display: "none",
  //   };
  // }

  return (
    <>
      <div className={styles.preview_wrapper}>
        <Space direction="vertical" size={10}>
          <Text strong className={styles.previewText}>
            Preview
          </Text>
          <div
            className={styles.preview}
            style={previewStyle}
            onMouseOver={() => {
              setHover(true);
            }}
            onMouseOut={() => {
              setHover(false);
            }}
          >
            <Space size={6}>
              {displayFlag && (
                <Avatar
                  size="small"
                  // src="https://cdn.countryflags.com/thumbs/india/flag-button-round-250.png"
                  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                  shape={flagStyle === "circle" ? "circle" : "square"}
                />
              )}

              <Text style={previewTextStyles}>IND</Text>
            </Space>
          </div>
        </Space>
      </div>
    </>
  );
};

export default Preview;
