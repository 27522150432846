import axios from "axios";

// function axios delete request call
const deleteCall = async (url) => {
  try {
    const res = await axios.delete(url);

    // console.log(res.data);
    if (res.data) {
      return {
        status: 200,
        data: res.data,
      };
    }
  } catch (error) {
    // console.log("somthing went wrong ", error.response.status);
    return {
      // status: error.response.status,
      // error: error.response.data.message,
      status: 401,
      error: "Something went wrong",
    };
  }
};

export default deleteCall;
