import React, { useContext } from "react";
import styles from "./UpgradePlanDetailsCard.module.less";
import { Card, Button, Timeline, message, Modal } from "antd";

import { CheckCircleTwoTone } from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import { AcsData, PlanData } from "../../../App";
import putCall from "../../../Http/putCall";
import deleteCall from "../../../Http/deleteCall";
import {freeMonthlyPlan, MONTHLY_PLAN_TYPE} from "../../../utils/plansData";
import classNames from "classnames";

const UpgradePlanDetailsCard = ({
  btnLoading,
  btnText,
  clickPlanName,
  currentPrice,
  planTargetAudience,
  isBtnActive,
  isBtnAnimated,
  isCurrentPrice,
  isFreeTrial,
  originalPrice,
  planCycle,
  planDetails,
  planName,
  recommendedText,
  style,
  trialDaysLeft,
  monthlyPlanPrice,
  extraTextAfterPrice = null,
}) => {
  const location = useLocation();
  const { settingData, globalState, setSession } = useContext(AcsData);
  const { setPlans, plans } = useContext(PlanData);

  const navigate = useNavigate();

  /// this function is used for upgrade and downgrade plans
  const handleClick = (clickPlanName) => {
    //#region constants
    // paid plan data object
    const defaultSettings = {
      displayFlag: false,
      flagStyle: "circle",
      autoSwitch: false,
      hideDecimal: false,
      roundDecimal: false,
      position : 'fixed',
      positionValue : 'top_with_right',
      supportedCurrencies: settingData.storeCurrency,
      defaultCurrency: settingData.storeCurrency,
    };
    // free plan data object
    const navigateWithState = path => navigate(
      path,
      { state: { isProgrammaticNavigation: settingData.isSetupCompleted, planCycle }}
    )
    //#endregion

    btnLoading.updateState(true);
    if (settingData.isSetupCompleted) {
      // when someone  click after onboarding
      if (clickPlanName === "premium") {
        // console.log("premium");
        navigateWithState("/acs/subscription/card");
      } else {
        // console.log("free");
        Promise.all([
          putCall("/plans", freeMonthlyPlan),
          deleteCall("/subscription"), //  use for cancel subscription
          putCall("/settings", defaultSettings), // use for set default settings when someone cancel subscription, so we need to change settings also
        ])
          .then(
            ([plansResponse, cancelSubscriptionResponse, settingsResponse]) => {
              if (
                plansResponse.status === 200 ||
                cancelSubscriptionResponse.status === 200 ||
                settingsResponse.status === 200
              ) {
                globalState(defaultSettings);
                setPlans(plansResponse.data.plan);
                // noinspection JSIgnoredPromiseFromCall
                message.success("Your plan is downgraded successfully");
                navigate("/acs/setting");
              } else {
                setSession(false);
                // message.error("somthing went worng");
              }
            }
          )
          .catch((err) => {
            // console.log("err while downgrade", err);
            setSession(false);
          })
          .finally(() => {
            btnLoading.updateState(false);
          });
      }
    } else {
      // when someone  click before onboarding
      if (clickPlanName === "premium") {
        navigateWithState("/pricing-plan/card");
      } else {
        Promise.all([
          putCall("/plans", freeMonthlyPlan),
          // deleteCall("/subscription"),
          putCall("/settings", { ...defaultSettings, stepReached: 1 }), // use for set default settings when subscription are cancel, so we need to set default settings according to plans
        ])
          .then(([plansResponse, settingsResponse]) => {
            if (
              plansResponse.status === 200 ||
              // cancelSubscriptionResponse.status === 200 ||
              settingsResponse.status === 200
            ) {
              globalState(defaultSettings);
              setPlans(plansResponse.data.plan);
              // message.success("downgrade successfully.");
              navigate("/acs/setup/step-1");
            } else {
              setSession(false);
              // message.error("somthing went worng");
            }
          })
          .catch((err) => {
            // console.log("err while downgrade", err);
            setSession(false);
          })
          .finally(() => {
            btnLoading.updateState(false);
          });
      }
    }
  };
  return (
    <>
      <div className={styles.upgradePlanPage_infoCard}>
        <Card style={{ ...style }}>
          {recommendedText && (
            <div className={styles.infoCard_recommended_section}>
              {recommendedText}
            </div>
          )}
          {extraTextAfterPrice}

          <div className={styles.infoCard_content}>
            <div className={styles.infoCard_title}>{planName}</div>
            <div className={styles.infoCard_price}>
              <span
                className={classNames(styles.original_price, clickPlanName !== 'free' && styles.hide)}
                style={isCurrentPrice ? { textDecoration: "line-through" } : {}}
              >
                ${planCycle === MONTHLY_PLAN_TYPE || clickPlanName === 'free' ? originalPrice : monthlyPlanPrice}
              </span>
              {isCurrentPrice && (
                <span className={styles.current_price}>${planCycle === MONTHLY_PLAN_TYPE ? currentPrice : (Number.parseFloat(currentPrice) / 12).toFixed(2)}</span>
              )}
              <span className={styles.month_text}>/mo</span>
            </div>

            {(clickPlanName !== 'free' && planCycle !== MONTHLY_PLAN_TYPE) && (
              <div className={styles.paid_annually}>
                pay annually
              </div>
            )}

            {clickPlanName !== 'free' && planCycle === MONTHLY_PLAN_TYPE && (
              <div className={styles.paid_annually}>
                pay monthly
              </div>
            )}

            {isFreeTrial && (
              <div className={styles.freeTrial_day}>
                {trialDaysLeft ?? '15'} Days Risk Free Trial
              </div>
            )}

            <div className={styles.infoCard_install_btn}>
              {isBtnActive ? (
                <Button
                  size="large"
                  loading={btnLoading.state}
                  className={
                    isBtnAnimated
                      ? `${styles.animatedBtn}`
                      : `${styles.normalBtn}`
                  }
                  onClick={() => {
                    if (clickPlanName === 'free' && location.state?.shouldRedirectToDashboard === true) {
                      navigate('/acs/setup/final-step');
                      return;
                    }
                    if (clickPlanName === 'free' && settingData.isSetupCompleted) {
                      // show modal
                      const modal = Modal.confirm({
                        type: 'warn',
                        style: {
                          maxWidth: 550,
                        },
                        width: '90vw',
                        title: 'Do you want to downgrade your plan?',
                        content: (
                          <ol>
                            <li>
                              Free plan supports maximum 3 currencies.
                            </li>
                            <li>
                              Added Currency will be removed from the list of currencies except store currency ({ settingData.storeCurrency }).
                            </li>
                            <li>
                              You can re-add other 2 currencies.
                            </li>
                          </ol>
                        ),
                        onOk: () => {
                          modal.destroy();
                          handleClick(clickPlanName);
                        },
                        onCancel: () => modal.destroy(),
                      });
                      return;
                    }
                    handleClick(clickPlanName);
                  }}
                >
                  {btnText}
                </Button>
              ) : (
                <Button
                  size="large"
                  loading={btnLoading.state}
                  className={
                    styles.normalBtn
                  }
                  onClick={() => {
                    const clickPlanName = 'free';
                    if (clickPlanName === 'free' && location.state?.shouldRedirectToDashboard === true) {
                      navigate('/acs/setup/final-step');
                      return;
                    }
                    if (clickPlanName === 'free' && settingData.isSetupCompleted) {
                      // show modal
                      const modal = Modal.confirm({
                        type: 'warn',
                        style: {
                          maxWidth: 550,
                        },
                        width: '90vw',
                        title: 'Do you want to downgrade your plan?',
                        content: (
                          <ol>
                            <li>
                              Free plan supports maximum 3 currencies.
                            </li>
                            <li>
                              Added Currency will be removed from the list of currencies except store currency ({ settingData.storeCurrency }).
                            </li>
                            <li>
                              You can re-add other 2 currencies.
                            </li>
                          </ol>
                        ),
                        onOk: () => {
                          modal.destroy();
                          handleClick(clickPlanName);
                        },
                        onCancel: () => modal.destroy(),
                      });
                      return;
                    }
                    handleClick(clickPlanName);
                  }}
                >
                  Downgrade
                </Button>
              )}
            </div>
            <div className={styles.infoCard_customers_used_details}>
              <span>{planTargetAudience} </span>
            </div>
            <div className={styles.infoCard_plan_desc}>
              <Timeline>
                {planDetails.map((planDetail, index) => {
                  return (
                    <Timeline.Item
                      dot={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                      key={index}
                    >
                      {planDetail}
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default UpgradePlanDetailsCard;
