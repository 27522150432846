import React, { useContext, useState } from "react";
import styles from "./StepFinal.module.less";
import {Space, Button, Card, message, Modal, Divider, Image} from "antd";
import partyFace from "../../../Assets/Images/partyFace.png";
import cryingFace from "../../../Assets/Images/cryingFace.png";
import {Link, useNavigate} from "react-router-dom";
import {AcsData, PlanData} from "../../../App";

import lastChanceLogo from '../../../Assets/Images/last-chance-logo.png';
import putCall from "../../../Http/putCall";
import {freeMonthlyPlan} from "../../../utils/plansData";

const StepFinal = () => {
  const navigate = useNavigate();
  const { plans } = useContext(PlanData);
  const [loading, setLoading] = useState(false);

  const { globalState, setSession } = useContext(AcsData);

  // get token
  // const token = sessionStorage.getItem("token");

  // handle Click events when the user clicks on the Go to dashboard button
  const handleclick = async () => {
    setLoading(true);
    const finalStepData = {
      isSetupCompleted: true,
      appEnable: true,
      stepReached: 6,
    };

    let res = await putCall("/settings", finalStepData);
    // console.log("finalStep", res);

    if (res.status === 200) {
      globalState(finalStepData);
      setLoading(false);
      navigate("/acs/dashboard");
    } else {
      // message.error(res.error);
      setSession(false);
    }

    // api aave pachhi aa delete karvu
    // globalState(finalStepData);
    // setLoading(false);
    // navigate("/acs/dashboard");
  };

  return (
    <>
      <div className={styles.stepFour_BodyWrapper}>
        <div className={styles.stepFour_boldText}>
          Please open your store and check it properly. if you don't see it on
          your store , please do hard refresh (Ctrl + F5) and check
        </div>
        {plans.name === freeMonthlyPlan.name && (
          <h2 style={{ textAlign: 'center' }}>
            <Link
              to='/pricing-plan'
              style={{ textDecoration: 'underline', color: '#00d800' }}
              state={{ shouldRedirectToDashboard: true }}
            >
             <Image preview={false} src={lastChanceLogo} style={{ width: '7rem' }} />{' '}
              Avail discount now!
            </Link>
          </h2>
        )}
        <div className={styles.stepFour_cardWrapper}>
          <Button
            type="primary"
            className={styles.stepFour_btn}
            onClick={handleclick}
            loading={loading}
          >
            Go to dashboard
          </Button>
        </div>
      </div>
    </>
  );
};

export default StepFinal;
