import React, {useState, useContext, useEffect} from "react";

import styles from "./ModalComponent.module.less";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Form, message } from "antd";
import ModalFooter from "../ModalFooter/ModalFooter";
import { AcsData } from "../../../App";

import putCall from "../../../Http/putCall";

// this is a main modal component which are render every modal in our app and change data of modal using props
const ModalComponent = ({
  header,
  footer,
  closable,
  isBackBtn = true,
  children,
  isOnboarding,
}) => {
  const { settingData, globalState, setSession } = useContext(AcsData);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const { supportedCurrencies, position, positionValue } = settingData;
  // console.log(typeof supportedCurrencies);

  // console.log("modal component ", settingData);

  // here we set default value for positionValue variabel because it will null by default in DB but we want to show somthing we cannot show null show we set default value for positionValue variabel
  let newpositionValue;
  if (
    (position === "inline" && positionValue === "") ||
    (position === "hide" && positionValue === "")
  ) {
    newpositionValue = "top_with_right";
  } else {
    newpositionValue = positionValue;
  }

  let currenciesArray;
  let currenciesString;

  // here we convert supportedCurrencies string to array of currencies because we want to use map method to show currencies list
  if (typeof supportedCurrencies === "string") {
    currenciesArray = supportedCurrencies.split(" ");
  } else if (typeof supportedCurrencies === "object") {
    currenciesString = supportedCurrencies.toString();
    currenciesArray = currenciesString.split(",");
  }

  // console.log("modal comp", currenciesArray, supportedCurrencies);

  //  state  for open and close modal
  const [isModalVisible, setIsModalVisible] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOk = () => {
    // setIsModalVisible(false);
  };

  // get token
  // let token = sessionStorage.getItem("token");

  // handleCancel function redirect to previous page when someone click on cancel button
  const handleCancel = () => {
    setIsModalVisible(false);
    navigate(-1);
    // console.log("click on onCancel");
    // if (location.pathname === "/acs/subscription/card") {
    //   navigate("/acs/subscription");
    // } else if (location.pathname === "/acs/subscription/plans") {
    //   navigate("/acs/subscription");
    // } else {
    //   navigate("/acs/setting");
    // }
  };

  const onFinish = (value) => {};
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  // antd form instance
  const [form] = Form.useForm();

  useEffect(() => {
    if (isOnboarding) {
      const str = sessionStorage.getItem('previouslySelectedCurrencies');

      if (str) {
        form.setFieldsValue({supportedCurrencies: JSON.parse(str)});
        form.validateFields(['supportedCurrencies']);
        sessionStorage.removeItem('previouslySelectedCurrencies')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  // this function call when someone clicks on save button after onbording and function is called according to url
  const onSave = async () => {
    // api call

    // here newData store a data which are updated by user and click on save button
    let newdata = form.getFieldsValue();
    // console.log("save-", newdata);

    setLoadingSave(true);

    if (location.pathname === "/acs/setting/currencies") {
      // update currencies setting and call put api for update db
      const { supportedCurrencies } = newdata;
      if (supportedCurrencies.length === 0) {
        message.error("required");
      } else {
        // convert currencyStrings to currencyArray because in database currency store in string format and we get here in array format
        const currenciesString = supportedCurrencies.join(" ");

        const currenciesData = {
          ...newdata,
          supportedCurrencies: currenciesString,
        };

        // let res = await setSettingData(currenciesData);

        // api call update db setting and globel state also
        let res = await putCall("/settings", currenciesData);

        if (res.status === 200) {
          message.success("Currencies settings successfully change ");
          globalState(currenciesData);
          setLoadingSave(false);
          navigate("/acs/setting");
        } else {
          setSession(false);
        }
      }
    } else if (location.pathname === "/acs/setting/location") {
      // if position is inline or hide so we want to give positionValue = ""

      if (newdata.position === "inline" || newdata.position === "hide") {
        newdata = {
          ...newdata,
          positionValue: "",
        };
      }

      // let res = await setSettingData(newdata);
      let res = await putCall("/settings", newdata);

      if (res.status === 200) {
        message.success("Location settings successfully change ");
        globalState(newdata);
        setLoadingSave(false);
        navigate("/acs/setting");
      } else {
        setSession(false);
      }
    } else if (location.pathname === "/acs/setting/design") {
      // update design settings

      // let res = await setSettingData(newdata);
      let res = await putCall("/settings", newdata);

      if (res.status === 200) {
        message.success("Design settings successfully change ");
        globalState(newdata);
        setLoadingSave(false);
        navigate("/acs/setting");
      } else {
        setSession(false);
      }
    } else if (location.pathname === "/acs/setting/pricing") {
      // update pricing Settings

      // let res = await setSettingData(newdata);
      let res = await putCall("/settings", newdata);

      if (res.status === 200) {
        message.success("Pricing settings successfully change ");
        globalState(newdata);
        setLoadingSave(false);
        navigate("/acs/setting");
      } else {
        setSession(false);
      }
    }
  };

  // this function is called in onbording when someone clicks on the next button
  const nextPage = async () => {
    //  api call

    // get all fome field value
    let updatedState = form.getFieldsValue();
    // console.log(updatedState);
    // api call
    setLoadingSave(true);
    // console.log("location in onbording 1", location.pathname);

    if (location.pathname === "/pricing-plan/card") {
      // in onbording if we are on pricing card page and we click next btn so we redirect to welcome page
      setLoadingSave(false);
      navigate("/welcome");
    }
    if (location.pathname === "/acs/setup/card") {
      // console.log("location in onbording 2", location.pathname);
      setLoadingCancel(false);
      navigate(-1);
    }
    if (location.pathname === "/acs/setup/step-1") {
      const { supportedCurrencies } = form.getFieldValue();

      /// convert supported currencies to string format
      const updatedSupportedCurrencies = supportedCurrencies.join(" ");
      const stepOneData = {
        ...updatedState,
        stepReached: 2,
        supportedCurrencies: updatedSupportedCurrencies,
      };
      // let res = await setSettingData(stepOneData);
      let res = await putCall("/settings", stepOneData);

      if (res.status === 200) {
        // message.success("Currencies settings successfully change ");
        globalState(stepOneData);
        setLoadingSave(false);
        navigate("/acs/setup/step-2");
      } else {
        setSession(false);
      }
    } else if (location.pathname === "/acs/setup/step-2") {
      // if position is inline or hide then we need to set positionValue is empty string
      if (
        updatedState.position === "inline" ||
        updatedState.position === "hide"
      ) {
        updatedState = {
          ...updatedState,
          positionValue: "",
        };
      }
      let stepTwoData = {
        ...updatedState,
        stepReached: 3,
      };
      // let res = await setSettingData(stepTwoData);
      let res = await putCall("/settings", stepTwoData);

      if (res.status === 200) {
        // message.success("Location settings successfully change ");
        globalState(stepTwoData);
        setLoadingSave(false);
        navigate("/acs/setup/step-3");
      } else {
        setSession(false);
      }
    } else if (location.pathname === "/acs/setup/step-3") {
      const stepThreeData = {
        ...updatedState,
        stepReached: 4,
      };
      // let res = await setSettingData(stepThreeData);
      let res = await putCall("/settings", stepThreeData);

      if (res.status === 200) {
        // message.success("Design settings successfully change ");
        globalState(stepThreeData);
        setLoadingSave(false);
        navigate("/acs/setup/step-4");
      } else {
        setSession(false);
      }
    } else if (location.pathname === "/acs/setup/step-4") {
      const stepFourData = {
        ...updatedState,
        stepReached: 5,
      };
      // let res = await setSettingData(stepFourData);
      let res = await putCall("/settings", stepFourData);

      if (res.status === 200) {
        // message.success("Pricing settings successfully change ");
        globalState(stepFourData);
        setLoadingSave(false);
        navigate("/acs/setup/final-step");
      } else {
        setSession(false);
      }
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...settingData,
          supportedCurrencies: currenciesArray.sort(),
          positionValue: newpositionValue,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // onValuesChange={(changeValue) => {
        //   console.log(changeValue);
        // }}
      >
        <div className="page__wrapper" id="modal-component">
          <Modal
            title={header ? header : null}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={location.pathname === "/acs/setup/final-step" ? 600 : 900}
            mask
            maskTransitionName={null}
            closable={closable}
            centered
            maskClosable={false}
            maskStyle={{ background: "#36363678" }}
            bodyStyle={{
              height: location.pathname === "/acs/setup/final-step" ? 270 : 450,
              overflow: "auto",
            }}
            getContainer={() => document.getElementById("modal-component")}
            footer={
              footer ? (
                <ModalFooter
                  nextPage={nextPage}
                  onSave={onSave}
                  loadingCancel={loadingCancel}
                  setLoadingCancel={setLoadingCancel}
                  closeModal={setIsModalVisible}
                  loadingSave={loadingSave}
                  isBackBtn={isBackBtn}
                />
              ) : null
            }
            className={styles.modal_component}
          >
            {children}
          </Modal>
        </div>
      </Form>
    </>
  );
};

export default ModalComponent;
