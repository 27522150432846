import React, { useContext } from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { AcsData } from "../../../App";

// component for displaying error message of page not found when someone hits not valid url
const NotFoundPage = () => {
  const { settingData, globalState } = useContext(AcsData);
  const navigate = useNavigate();
  const notFoundPageStyles = {
    width: "100%",
    height: "100vh",
    marginTop: "60px",
  };

  // function for redirect use into dashboard or welcome page if someone hits not valid url
  const handleclick = () => {
    // console.log(settingData.stepReached);
    if (settingData.isSetupCompleted) {
      navigate("/acs/dashboard");
    } else if (settingData.stepReached === 0) {
      navigate("/welcome");
    } else {
      navigate(`/acs/setup/step-${settingData.stepReached}`);
    }
  };
  return (
    <>
      <Result
        style={notFoundPageStyles}
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={handleclick}>
            Back Home
          </Button>
        }
      />
    </>
  );
};

export default NotFoundPage;
