import React from "react";
import ModalComponent from "../../Shared/ModalComponent/ModalComponent";

import styles from "./DesignSetting.module.less";

import ModalHeader from "../../Shared/ModalHeader/ModalHeader";

import Step3 from "../../Shared/Steps/Step3/Step3";
import Preview from "../../Shared/Preview/Preview";

// design settings component which display after onboarding in settings section

const DesignSetting = () => {
  return (
    <>
      <ModalComponent
        header={
          <ModalHeader
            heading="Design Setting"
            sunHeading="Now, let's decide the Look of the currency switcher in your store."
          />
        }
        footer={true}
      >
        <div className={styles.modalBody_wrapper}>
          <div className={styles.modalBody_leftSide}>
            <Step3 />
          </div>
          <div className={styles.modalBody_rightSide}>
            <Preview />
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default DesignSetting;
