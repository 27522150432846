import React from "react";
import styles from "./StepThree.module.less";

import Step3 from "../../Shared/Steps/Step3/Step3";
import Preview from "../../Shared/Preview/Preview";

// step 3 component (design settings)
const StepThree = () => {
  return (
    <>
      <div className={styles.modalBody_wrapper}>
        <div className={styles.modalBody_leftSide}>
          <Step3 />
        </div>
        <div className={styles.modalBody_rightSide}>
          <Preview />
        </div>
      </div>
    </>
  );
};

export default StepThree;
