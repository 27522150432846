import axios from "axios";

// axios request interceptors for get token form sessionStorage and send token in every request
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // If the header does not contain the token and the url not public, redirect to login
    config.baseURL = process.env.REACT_APP_API_HOST;

    // check the environment and if the environment is production or development so get token and pass token in headers
    if (
      process.env.REACT_APP_ENVIORNMENT === "production" ||
      process.env.REACT_APP_ENVIORNMENT === "development"
    ) {
      const accessToken = sessionStorage.getItem("token");
      // if token is found add it to the header
      if (accessToken) {
        if (config.method !== "OPTIONS") {
          config.headers.authorization = `Bearer ${accessToken}`;
        }
      } else {
        return Promise.reject();
      }
      return config;
    }

    // Local Enviornment

    // if environment is local then use static token for auth
    if (process.env.REACT_APP_ENVIORNMENT === "local") {
      // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWJkb21haW4iOiJhbXJpc2gtcy1zY2hvb2wtZDAyZS50aGlua2lmaWMuY29tIiwiaWF0IjoxNjQ4NzMwOTg2fQ.maYaFeP2if58SDgmpuNwBVSSODBgzyqZhyth_zTYL7s";

      const accessToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWJkb21haW4iOiJhbXJpc2gtcy1zY2hvb2wtZDAyZS50aGlua2lmaWMuY29tIiwiaWF0IjoxNjQ4NzMwOTg2fQ.maYaFeP2if58SDgmpuNwBVSSODBgzyqZhyth_zTYL7s";
      // if token is found add it to the header

      // const accessToken = 123;
      if (accessToken) {
        if (config.method !== "OPTIONS") {
          config.headers.authorization = `Bearer ${accessToken}`;
        }
      } else {
        return Promise.reject();
      }
    }
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

export default axios;
