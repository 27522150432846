import React, { useContext } from "react";
import ChromePicker from "react-color/lib/Chrome";
import { toState } from "react-color/lib/helpers";
import { AcsData } from "../../../../App";
// form mathi je initialValues aave te as a state use kari sakai

export default function CustomChromePicker({ onChange, value = "", ...props }) {
  const { globalState } = useContext(AcsData);
  const { id } = props;
  return (
    <ChromePicker
      {...props}
      color={toState(value)}
      disableAlpha
      onChange={({ hex }) => {
        onChange(hex);
        if (id === "textColor") {
          globalState({
            textColor: hex,
          });
        } else if (id === "textHoverColor") {
          globalState({
            textHoverColor: hex,
          });
        } else if (id === "backgroundColor") {
          globalState({
            backgroundColor: hex,
          });
        } else if (id === "backgroundHoverColor") {
          globalState({
            backgroundHoverColor: hex,
          });
        } else if (id === "borderLineColor") {
          globalState({
            borderLineColor: hex,
          });
        }
      }}
    />
  );
}
