import React, { useContext, useState } from "react";
import { Typography, Space, Form, Switch, Row, Col, Select, Tag } from "antd";
import styles from "./Step4.module.less";
import cardDetails from "../../../../Assets/Images/card-details.png";
import { AcsData, PlanData } from "../../../../App";

const { Text } = Typography;
const { Option } = Select;
const Step4 = () => {
  // this use for identifies which field are includes in pro plans and which are includes in free plans
  const { plans } = useContext(PlanData);
  const { customerType } = useContext(AcsData);
  return (
    <>
      <div className={styles.stepFour_wrapper}>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ marginTop: "20px" }}
        >
          <Col xs={24} lg={15} xl={15} md={15} sm={15}>
            <Space direction="vertical" size={0}>
              <Text strong className={styles.stepFour_boldText}>
                Show Price Format
              </Text>
              <Text strong className={styles.stepFour_smallText}>
                {/* Ex: With / without Currency - : $50 USD / $ 50 */}
                Ex: With Currency - $50 USD
                <br /> &nbsp; &nbsp; &nbsp; Without Currency - $50
              </Text>
            </Space>
          </Col>
          <Col
            className={styles.currencyFormat_selectBox}
            xs={24}
            lg={9}
            xl={9}
            md={9}
            sm={9}
          >
            <Form.Item name="currencyFormat">
              <Select size="large">
                <Option value="with" key="with">
                  With Currency
                </Option>
                <Option value="without" key="without">
                  Without Currency
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className={styles.stepFour_switch} style={{ marginTop: "30px" }}>
          <Space direction="vertical" size={0}>
            <Text strong className={styles.stepFour_boldText}>
              Auto switch currency based on IP Location{' '}
              {/*<ProTagPopover />*/}
            </Text>
            <Text strong className={styles.stepFour_smallText}>
              Customers from different countries will see the prices in their
              local currency by default
            </Text>
          </Space>
          <Form.Item name="autoSwitch" valuePropName="checked">
            <Switch
              // disabled={
              //   plans.name === "free" ||
              //   (customerType.customerType === "new" && plans.name === "free")
              // }
            />
          </Form.Item>
        </div>

        <Form.Item dependencies={["hideDecimal", "roundDecimal"]}>
          {({ setFieldsValue }) => (
            <>
              <div className={styles.stepFour_switch}>
                <Space direction="vertical" size={0}>
                  <Text strong className={styles.stepFour_boldText}>
                    Remove Decimals{' '}
                    {/*<ProTagPopover />*/}
                  </Text>
                  <Text strong className={styles.stepFour_smallText}>
                    Customers will see prices without decimal values.
                  </Text>
                  <Text strong className={styles.stepFour_smallText}>
                    Ex: Show $ 50 instead of $ 50.67
                  </Text>
                </Space>
                <Form.Item
                  name="hideDecimal"
                  valuePropName="checked"
                  // this is use because we want to disable hideDecimal field when roundDecimal field are active
                  normalize={(value) => {
                    value &&
                      setFieldsValue({
                        roundDecimal: false,
                      });
                    return value;
                  }}
                >
                  <Switch
                    // disabled={
                    //   plans.name === "free" ||
                    //   (customerType.customerType === "new" &&
                    //     plans.name === "free")
                    // }
                  />
                </Form.Item>
              </div>
              <div
                className={styles.stepFour_switch}
                style={{ marginTop: "30px" }}
              >
                <Space direction="vertical" size={0}>
                  <Text strong className={styles.stepFour_boldText}>
                    Pricing Rounding Rules{' '}
                    {/*<ProTagPopover />*/}
                  </Text>
                  <Text strong className={styles.stepFour_smallText}>
                    Rounding Decimals Ex: Show $ 50.00 instead of $ 50.37
                  </Text>
                </Space>
                <Form.Item
                  name="roundDecimal"
                  valuePropName="checked"
                  // this is use because we want to disable roundDecimal field when  hideDecimal field are active
                  normalize={(value) => {
                    value &&
                      setFieldsValue({
                        hideDecimal: false,
                      });
                    return value;
                  }}
                >
                  <Switch
                    // disabled={
                    //   plans.name === "free" ||
                    //   (customerType.customerType === "new" &&
                    //     plans.name === "free")
                    // }
                  />
                </Form.Item>
              </div>
            </>
          )}
        </Form.Item>
      </div>
    </>
  );
};

export default Step4;
