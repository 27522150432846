import { Navigate } from "react-router-dom";

// function for authentication of routes

// authentication for other routes which are not includes in onboarding
export default function ProtectedRoute({ settings, children }) {
  if (settings.isSetupCompleted) {
    return children;
  }

  return <Navigate to="/" />;
}

// authentication for onboarding routes
export function ProtectedRouteOnBording({ settings, children }) {
  if (!settings.isSetupCompleted) {
    return children;
  }

  return <Navigate to="/acs/dashboard" />;
}
