import React, { useContext } from "react";
import { Typography, Space, Progress } from "antd";
import { Outlet } from "react-router-dom";
import styles from "./OnboardingModalComponent.module.less";
import { useLocation } from "react-router-dom";

import ModalComponent from "../../Shared/ModalComponent/ModalComponent";
import ModalHeader from "../../Shared/ModalHeader/ModalHeader";
import { AcsData } from "../../../App";
const { Text } = Typography;

// onboarding modal component
const OnboardingModalComponent = () => {
  const location = useLocation();
  const { settingData, globalState } = useContext(AcsData);

  // console.log(location.pathname);
  // console.log("modal rendered", settingData.stepReached);

  let header;
  // display header according to stepReached value
  switch (settingData.stepReached) {
    case 1:
      header = (
        <ModalHeader
          heading="Step 1:"
          sunHeading="Let's choose the currencies that you want to choose in your Shopify store out of 175+ currencies"
        />
      );
      break;
    case 2:
      header = (
        <ModalHeader
          heading="Step 2:"
          sunHeading="Now, let's decide the location of the currency switcher in your store"
        />
      );
      break;
    case 3:
      header = (
        <ModalHeader
          heading="Step 3:"
          sunHeading="Now, let's decide the Look of the currency switcher in your store."
        />
      );
      break;
    case 4:
      header = (
        <ModalHeader
          heading="Step 4:"
          sunHeading="Let's customize the product prices after conversion like rounding the proper digits or hiding the decimal places so that your customers will always see the cleaner prices"
        />
      );
      break;
    default:
      break;
  }

  // final step header component
  const finalStepHeader = (
    <>
      <div className={styles.stepFour_finalStepHeader}>
        <Progress type="circle" percent={100} size="small" />
        <Space direction="vertical" size={0}>
          <Text strong className={styles.stepFour_headerText}>
            Multi Currency Converter is
          </Text>
          <Text strong className={styles.stepFour_headerText}>
            setup on your store
          </Text>
        </Space>
      </div>
    </>
  );

  return (
    <>
      <div className="page__wrapper" id="onboarding-modal">
        <ModalComponent
          header={
            location.pathname === "/acs/setup/final-step"
              ? finalStepHeader
              : header
          }
          isOnboarding={true}
          footer={true}
          closable={false}
        >
          {/* Outlet means steps component according to stepReached value */}
          <Outlet />
        </ModalComponent>
      </div>
    </>
  );
};

export default OnboardingModalComponent;
