import {Form, Image} from "antd";
import React from "react";

import switcherHiddenImg from "../Assets/Images/switcherHidden.png";
import switcherInlineHeaderImg from "../Assets/Images/switcherInlineHeader.png";
import switcherTopRightImg from "../Assets/Images/switcherTopRight.png";
import switcherTopLeftImg from "../Assets/Images/switcherTopLeft.png";
import switcherBottomLeftImg from "../Assets/Images/switcherBottomLeft.png";
import switcherBottomRightImg from "../Assets/Images/switcherBottomRight.png";

export default function LocationPreviewImage() {
  return (
    <Form.Item shouldUpdate>
      {(formInstance)=> {
        const {position, positionValue} = formInstance.getFieldsValue(['position', 'positionValue']);
        let imageSrc = null;

        if (position === 'hide') {
          imageSrc = switcherHiddenImg
        } else if (position === "inline") {
          imageSrc = switcherInlineHeaderImg;
        } else {
          switch(positionValue) {
            case 'top_with_right':
              imageSrc = switcherTopRightImg;
              break;
            case 'top_with_left':
              imageSrc = switcherTopLeftImg;
              break;
            case 'bottom_with_left':
              imageSrc = switcherBottomLeftImg;
              break;
            case 'bottom_with_right':
            default:
              imageSrc = switcherBottomRightImg;
              break;
          }
        }

        return (
          <Image src={imageSrc} />
        );
      }}
    </Form.Item>
  )
}