import React, { useContext } from "react";
import styles from "./SettingPage.module.less";
import {
  Row,
  Card,
  Tag,
  Space,
  Button,
  Divider,
  Typography,
  Popconfirm,
  Switch,
  message,
} from "antd";
import designSettings from "../../../Assets/Images/invoiceDesignSettings.png";
import currencySetting from "../../../Assets/Images/currencySetting.png";
import pricingRules from "../../../Assets/Images/pricingRules.png";
import SettingElementCard from "../../Shared/SettingElementCard/SettingElementCard";
import { AcsData } from "../../../App";
import switcherLocation from "../../../Assets/Images/switcher-location.png";
import { Outlet } from "react-router-dom";
import putCall from "../../../Http/putCall";

const { Text } = Typography;

// settings page layout component

const SettingMainComponent = () => {
  const { settingData, globalState, setSession } = useContext(AcsData);
  // get token
  // let token = sessionStorage.getItem("token");
  let appStatus;
  if (settingData.appEnable) {
    appStatus = "Enabled";
  } else {
    appStatus = "Disabled";
  }

  // function for handle click event on switch
  const handleChange = async () => {
    const data = {
      appEnable: !settingData.appEnable,
    };
    let res = await putCall("/settings", data);

    if (res.status === 200) {
      // api aave pachhi aa delete karvu
      globalState(data);
      message.success(
        `Auto Currency Switcher ${
          !settingData.appEnable ? "Enabled" : "Disabled"
        } successfully`
      );
    } else {
      setSession(false);
    }
  };

  return (
    <>
      <div className={styles.settingPage_inner_content}>
        {/* <div className={styles.appEnabel_content}> */}
        <Card className={styles.enabled_app_card}>
          <div className={styles.enabled_card_content}>
            <Space className={styles.enabled_card_text} size={10}>
              <Text strong>Multi Currency Converter app is</Text>
              <Tag color={settingData.appEnable ? "green" : "red"}>
                {appStatus}
              </Tag>
            </Space>

            {/* <Button
              ghost
              type="primary"
              className={styles.enabled_btn}
              // onClick={handleclick}
            >
              Edit
            </Button> */}
            <div className={styles.enabled_switch}>
              {/* switch for enabled and disabled app  */}
              <Switch checked={settingData.appEnable} onChange={handleChange} />
            </div>
          </div>
        </Card>
        {/* </div> */}
        <Divider className={styles.settingPge_divider} />
        <div className={styles.settingPage_element}>
          <Row justify="center" wrap={true}>
            <SettingElementCard
              image={currencySetting}
              firstLineText="Currencies"
              secondLineText="List"
              onClickUrl="currencies"
            />
            <SettingElementCard
              image={switcherLocation}
              firstLineText="Switcher"
              secondLineText="Location"
              onClickUrl="location"
              proPlan={true}
            />
            <SettingElementCard
              image={designSettings}
              firstLineText="Design"
              secondLineText="Settings"
              proPlan={true}
              onClickUrl="design"
            />
            <SettingElementCard
              image={pricingRules}
              firstLineText="Pricing"
              secondLineText="Rules"
              proPlan={true}
              onClickUrl="pricing"
            />
          </Row>
          {/* <Divider className={styles.settingPge_divider} /> */}
        </div>
      </div>
    </>
  );
};

export default SettingMainComponent;
