import React from "react";
import { Space, Typography } from "antd";
import styles from "./ModalHeader.module.less";
const { Text } = Typography;

// common header component for every modal component (header change according to modal props)
const ModalHeader = ({ heading, sunHeading, isHeadingCenter = false }) => {
  return (
    <>
      <Space direction="vertical" size={0} style={{ width: "100%" }}>
        <div
          className="bhautiktext"
          style={{
            textAlign: isHeadingCenter ? "center" : "initial",
            fontSize: "18px",
          }}
        >
          {heading}
        </div>
        <Text className={styles.modal_headerText}>{sunHeading}</Text>
      </Space>
    </>
  );
};

export default ModalHeader;
