import React, { useContext, useEffect, useState } from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import { message } from "antd";

import ModalComponent from "../../Shared/ModalComponent/ModalComponent";
import ModalHeader from "../../Shared/ModalHeader/ModalHeader";

import PaymentCardUI from "../../Shared/PaymentCardUI/PaymentCardUI";
import { Spin } from "antd";
import getCall from "../../../Http/getCall";
import {AcsData, PlanData} from "../../../App";
import populateSubscriptionAndUpdatePlans from "../../../utils/populateSubscriptionAndUpdatePlans";
import {MONTHLY_PLAN_TYPE, premiumMonthlyPlan, premiumYearlyPlan} from "../../../utils/plansData";

const CardPayment = () => {
  const { setPlans, plans } = useContext(PlanData);
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  // state for find that card is added or not
  const [isCardAdded, setIsCardAdded] = useState(false);
  const [loading, setLoading] = useState(true);

  // if card added already then store card data in cardData sate variable
  const [cardData, setCardData] = useState({});
  const {
    settingData,
    // globalState,
    setSession,
  } = useContext(AcsData);

  useEffect(() => {
    const getCardDetails = async () => {
      // api call for get data about that user are already card added or not
      const res = await getCall("/cardDetails");
      if (res.status === 200) {
        const isCardAdded = res.data.data !== "No details found";
        if (settingData.isSetupCompleted && !locationState?.isProgrammaticNavigation && !isCardAdded) {
          // after setup, is on free plan and tryed to access this page
          navigate(-1);
          message.warn('Unauthorized access!');
          return;
        }

        if (!isCardAdded) {
          setIsCardAdded(false);
          setLoading(false);
        } else {
          const cardData = res.data;
          if (locationState?.isProgrammaticNavigation) {
            // update plan was selected
            const { planCycle } = locationState;
            const result = await populateSubscriptionAndUpdatePlans(
              planCycle,
              false,
              planCycle === MONTHLY_PLAN_TYPE ? premiumMonthlyPlan : premiumYearlyPlan,
            );
            if (!result) {
              setSession(false);
              return;
            }
            setPlans(result.plansUpdateRes.data.plan);
            message.success('Plan upgraded successfully!');
            navigate('/acs/subscription/plans');
            return;
          }
          setCardData(cardData);
          setIsCardAdded(true);
          setLoading(false);
        }
      } else {
        setSession(false);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    getCardDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalComponent
        header={
          !isCardAdded && !loading ? (
            <ModalHeader heading={Object.keys(cardData).length > 0 ? 'Replace your card here' : "Add your card here"} isHeadingCenter={true} />
          ) : null
        }
        closable={settingData.isSetupCompleted && !loading}
        footer={!settingData.isSetupCompleted && isCardAdded}
        isBackBtn={false}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" tip='Loading...' />
          </div>
        ) : (
          <PaymentCardUI
            isCardAdded={isCardAdded}
            setIsCardAdded={setIsCardAdded}
            cardData={cardData}
            setCardData={setCardData}
          />
        )}
      </ModalComponent>
    </>
  );
};

export default CardPayment;
