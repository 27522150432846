// noinspection ExceptionCaughtLocallyJS

import postCall from "../Http/postCall";
import putCall from "../Http/putCall";

export default async function populateSubscriptionAndUpdatePlans(planCycle, updateCardDetails, plansData, populateSubToken = undefined) {
  try {
    const populateSubRes = await postCall("/populateSubscription", {planType: planCycle, updateCardDetails, token: populateSubToken});
    if (populateSubRes.status !== 200) {
      throw Error('Popoulate subscription failed!');
    }
    const plansUpdateRes = await putCall("/plans", plansData);
    if (plansUpdateRes.status !== 200) {
      throw Error('Plans update failed!')
    }

    return {populateSubRes, plansUpdateRes}
  } catch (err) {
    console.error(err);
    return false;
  }
}