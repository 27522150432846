import React from "react";
import styles from "./StepTwo.module.less";

import Step2 from "../../Shared/Steps/Step2/Step2";
// import Preview from "../../Shared/Preview/Preview";
import LocationPreviewImage from "../../LocationPreviewImage";

// step 2 component (location settings)
const StepTwo = () => {
  return (
    <>
      <div className={styles.modalBody_wrapper}>
        <div className={styles.modalBody_leftSide}>
          <Step2 />
        </div>
        <div className={styles.modalBody_rightSide}>
          <LocationPreviewImage />
        </div>
      </div>
    </>
  );
};

export default StepTwo;
