import { Space, Typography, Card, Image, Button } from "antd";
import React from "react";
import styles from "./HelpPage.module.less";
import ceo_image from "../../../Assets/Images/CEO.jpg";

const { Text } = Typography;

// HelpPage component

const HelpPage = () => {
  return (
    <>
      <div className={styles.helpPage_wrapper}>
        <div className={styles.helpPage_content}>
          <Space direction="vertical" size={20}>
            <div className={styles.helpPage_heading}>
              <Space direction="vertical" size={0}>
                <Text strong className={styles.boldText}>
                  Dedicated Relationship Manager
                </Text>
                <Text className={styles.normal_text}>
                  Need any help with setup or customization? Our team is always
                  there to resolve your queries.
                </Text>
              </Space>
            </div>

            <Card className={styles.helpPage_card}>
              <div className={styles.helpPage_avatar_wrapper}>
                <Space direction="vertical" size={3}>
                  <Image
                    width={150}
                    src={ceo_image}
                    className={styles.helpPage_avatar}
                  />
                  <Text strong className={styles.card_heading}>
                    Rajesh
                  </Text>
                  <Text className={styles.normal_text}>
                    Multi Currency Converter expert!
                  </Text>
                </Space>
              </div>
              <div className={styles.card_text_wrapper}>
                <Text className={styles.normal_text}>
                  Send an email to
                  <Button
                    type="link"
                    className={styles.linkBtn}
                    onClick={() => {
                      window.location.href = "mailto:support@mlveda.com";
                    }}
                  >
                    support@mlveda.com
                  </Button>
                  We will reply you within one business day.
                </Text>
              </div>
            </Card>

            <Card className={styles.helpPage_card}>
              <Text>
                <Text strong>Note:</Text> Please provide your store access to{" "}
                <Button
                  type="link"
                  className={styles.linkBtn}
                  onClick={() => {
                    window.location.href = "mailto:support@mlveda.com";
                  }}
                >
                  support@mlveda.com
                </Button>
                if it requires us to access your store.
              </Text>
            </Card>
          </Space>
        </div>
      </div>
    </>
  );
};

export default HelpPage;
