import React, { useContext, useState } from "react";
import styles from "./WelcomePage.module.less";
import { Card, Image, Divider, Button, Typography, Space, message } from "antd";
import ceo_image from "../../../Assets/Images/CEO.jpg";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AcsData } from "../../../App";
import putCall from "../../../Http/putCall";
const { Text } = Typography;

// welcome page

const Welcome = () => {
  const navigate = useNavigate();
  const { settingData, globalState, setSession } = useContext(AcsData);
  const [loading, setLoading] = useState(false);

  // const token = sessionStorage.getItem("token");
  // console.log(token);

  // const headers = {
  //   authorization: `Bearer ${token}`,
  // };

  // console.log("welcome page", headers);

  // update stepReached value when someone click on start setup button
  const updateSetting = async () => {
    navigate("/pricing-plan");

    // setLoading(true);
    // const data = {
    //   stepReached: 1,
    // };
    //
    // // putapi call to for update settings
    // const res = await putCall("/settings", data);
    // console.log(res);
    //
    // if (res.status === 200) {
    //   // update globel state
    //   globalState({
    //     stepReached: 1,
    //   });
    //   setLoading(false);
    //   navigate("/acs/setup/step-1");
    // } else {
    //   setSession(false);
    // }
  };

  return (
    <>
      <div className={styles.welcomePage_cardWrapper}>
        <Card className={styles.welcomePage_card}>
          <div className={styles.avatar_wrapper}>
            <Image
              preview={false}
              className={styles.welcomePage_avatar}
              width={100}
              src={ceo_image}
            />
          </div>
          <Divider className={styles.divider} />
          <div className={styles.welcomePage_textWrapper}>
            <Space direction="vertical" size={16}>
              <Text strong className={styles.bold_text}>
                Hi,
              </Text>
              <Text className={styles.normal_text}>
                I'm Rajesh, the VP of Product at MLVeda. I wanted to personally
                welcome you and help you setup currency switcher app on your
                store.
              </Text>
              <Text className={styles.normal_text}>
                I will guide you step by step. At any point if you need any help
                feel free to send a mail to
                <Button
                  type="link"
                  onClick={() => {
                    window.location.href = "mailto:support@mlveda.com";
                  }}
                >
                  support@mlveda.com
                </Button>
              </Text>
              <Text className={styles.normal_text}>
                I wish huge success to you and your store.
              </Text>
            </Space>
          </div>
          <iframe className={styles.setupVideo} src="https://www.youtube.com/embed/16XPy6mY22Q"
                  title="Multi Currency Converter app demo" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
          <div className={styles.welcomePage_btnWrapper}>
            <Button
              type="primary"
              className={styles.startBtn}
              onClick={updateSetting}
              loading={loading}
            >
              <Space>
                <Text className={styles.btn_boldText}>Start Setup</Text>
                <Text className={styles.btn_smallText}>
                  (Takes less than 60 sec)
                </Text>
                <i className="las la-long-arrow-alt-right"></i>
              </Space>
            </Button>
            {/* <Button type="link" className={styles.upgradeBtn}>
              <Text>Upgrade to Super Success plan </Text>
              <i className="las la-angle-right"></i>
            </Button> */}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Welcome;
