import React, {useContext, useEffect, useState} from "react";
import styles from "./UpgradePlanPage.module.less";
import { Typography } from "antd";

import UpgradePlanDetailsCard from "../../Shared/UpgradePlanDetailsCard/UpgradePlanDetailsCard";
import backIcon from "../../../Assets/Images/back-icon.png";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import { AcsData, PlanData } from "../../../App";
import BillingFrequencySwitch from "./BillingFrequencySwitch";
import {
  freeYearlyPlan,
  MONTHLY_PLAN_TYPE,
  premiumMonthlyPlan,
  premiumYearlyPlan,
  YEARLY_PLAN_TYPE
} from "../../../utils/plansData";

import sadFaceImage from '../../../Assets/Images/sadFace.png';
import smillingFaceImage from '../../../Assets/Images/smilingFaceWithHeartEyes.png'

const { Text } = Typography;
const freePlanDetails = [
  <span className={styles.planFeatureContainer}>
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <span>Only 3 currencies supported</span> <img src={sadFaceImage} />
  </span>,
  "IP based auto currency switching",
  "Beautiful Country Flags",
  "Rounded Pricing for better conversions",
  "Free Look and Feel Customisation",
  "Location based Currency Selection",
  "Chat Support",
];
const paidPlanDetails = [
  <span className={styles.planFeatureContainer}>
    <span>160+ currencies Currencies Supported</span>{' '}
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img src={smillingFaceImage} />
  </span>,
  "IP based auto currency switching",
  "Beautiful Country Flags",
  "Rounded Pricing for better conversions",
  "Free Look and Feel Customisation",
  "Email/Phone/Chat Support",
  "Free UX support to integrate with your theme",
  "Location based Currency Selection",
  "Priority, Urgent setup support Reply within 24 hours",
];

// this function help to display button according to current plan
// (if plan is free so display premium button only)
const showBtn = (planName, settingData, plans, planCycle) => {
  if (settingData.isSetupCompleted) {
    if (planName === 'free') {
      return plans.name !== planName;
    }
    return !(plans.name === planName && plans.type === planCycle);
  }
  return true;
};

const FreePlan = ({
  plans,
  settingData,
  planCycle,
}) => {
  const [freePlanBtnLoding, setFreePlanBtnLoding] = useState(false);

  return (
    <UpgradePlanDetailsCard
      style={{
        height: "596px",
        border: settingData.isSetupCompleted
          ? plans.name === "free"
            ? "2px solid black"
            : ""
          : "",
      }}
      recommendedText={
        settingData.isSetupCompleted
          ? plans.name === "free"
            ? "Current plan"
            : null
          : null
      }
      currentPrice=""
      isCurrentPrice={false}
      originalPrice="0"
      isBtnActive={showBtn("free", settingData, plans, planCycle)}
      planName="Hobbyist"
      btnLoading={{
        state: freePlanBtnLoding,
        updateState: setFreePlanBtnLoding,
      }}
      isFreeTrial={false}
      isBtnAnimated={false}
      btnText={
        settingData.isSetupCompleted
          ? plans.name === "premium"
            ? "Select"
            : "Install"
          : plans.name === 'free' && plans.status === 'active'
            ? "Continue"
            : "Install"
      }
      planTargetAudience="Just for testing"
      clickPlanName="free"
      planDetails={freePlanDetails}
      planCycle={planCycle}
    />
  );
}

const PaidPlan = ({
  plans,
  settingData,
  planCycle,
}) => {
  const [premiumPlanBtnLoading, setpremiumPlanBtnLoading] = useState(false);

  return (
    <UpgradePlanDetailsCard
      extraTextAfterPrice={
        planCycle === YEARLY_PLAN_TYPE && (Object.keys(plans).length === 0 || plans.name === freeYearlyPlan.name) && (
          <span className={styles.offText}>
            <span>30% Off</span>
          </span>
        )
    }
      trialDaysLeft={plans.trialdaysleft}
      style={{
        border: settingData.isSetupCompleted
          ? plans.name === "premium"
            ? "2px solid black"
            : ""
          : "",
      }}
      planCycle={planCycle}
      recommendedText={
        settingData.isSetupCompleted
          ? plans.name === "premium" && planCycle === plans.type
            ? "Current plan"
            : "Highly Recommended"
          : "Highly Recommended"
      }
      currentPrice={planCycle === MONTHLY_PLAN_TYPE ? premiumMonthlyPlan.price : premiumYearlyPlan.price}
      btnText={
        settingData.isSetupCompleted
          ? plans.name === "free"
            ? planCycle === YEARLY_PLAN_TYPE ? "Avail offers now" : "Give it a try"
            : "Switch"
          : "Install"
      }
      isCurrentPrice={true}
      isBtnActive={showBtn("premium", settingData, plans, planCycle)}
      originalPrice={planCycle === MONTHLY_PLAN_TYPE ? premiumMonthlyPlan.originalPrice : premiumYearlyPlan.originalPrice}
      btnLoading={{
        state: premiumPlanBtnLoading,
        updateState: setpremiumPlanBtnLoading,
      }}
      planName="Supper Success"
      isFreeTrial={true}
      isBtnAnimated={true}
      clickPlanName="premium"
      planTargetAudience="Recommended for professional course creators"
      planDetails={paidPlanDetails}
      monthlyPlanPrice={premiumMonthlyPlan.price}
    />
  );
}

const PageTitle = ({ settingData, plans }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.upgradePlanPage_heading}>
      {settingData.isSetupCompleted && (
        <img
          src={backIcon}
          alt="back icons"
          className={styles.backIcon}
          onClick={() => {
            navigate("/acs/subscription");
          }}
        />
      )}
      <Text className={styles.upgradePlanPage_heading_text}>
        {settingData.isSetupCompleted && plans.trialdaysleft < 1
          ? 'You have already used our free trial period'
          : `Try our Super Success plan free for ${plans?.trialdaysleft ?? '15'} days now.`}
      </Text>
    </div>
  )
}

const UpgradePlanPage = () => {
  const { settingData } = useContext(AcsData);
  const { plans } = useContext(PlanData);
  const [planCycle, setPlanCycle] = useState(YEARLY_PLAN_TYPE);
  const location = useLocation();

  useEffect(() => {
    if (settingData.isSetupCompleted && plans && plans.type && Object.keys(plans).length > 0 && plans.name !== freeYearlyPlan.name) {
      if (plans.status !== 'active') {
        setPlanCycle(YEARLY_PLAN_TYPE)
      } else {
        setPlanCycle(plans.type);
      }
    } else {
      setPlanCycle(YEARLY_PLAN_TYPE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans])

  // if incomplete on-boarding and already purchased plan
  if (!settingData?.isSetupCompleted && plans?.status === 'active' && location.state?.shouldRedirect !== false) {
    if (location.state?.shouldRedirectToDashboard !== true) {
      return (
        <Navigate to='/acs/setup/step-1'/>
      )
    }
  }

  return (
    <>
      <div className={styles.upgradePlanPage_wrapper}>
        <div className={styles.upgradePlanPage_content}>
          <PageTitle
            plans={plans}
            settingData={settingData}
          />

          <div className={styles.planSwitchContainer}>
            <BillingFrequencySwitch value={planCycle} onChange={newPlan => setPlanCycle(newPlan)} />
          </div>

          <div className={styles.upgradePlanPage_infoCard_wrapper}>
            {/* display free plan details */}
            <FreePlan
              plans={plans}
              settingData={settingData}
              planCycle={planCycle}
            />
            {/* display premium plan details */}
            <PaidPlan
              plans={plans}
              settingData={settingData}
              planCycle={planCycle}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpgradePlanPage;
