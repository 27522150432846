import React, {useContext, useMemo, useState} from "react";
import styles from "./PaymentsPage.module.less";
import {Row, Tooltip} from "antd";
import SettingElementCard from "../../Shared/SettingElementCard/SettingElementCard";
import addCard from "../../../Assets/Images/addCard.png";
import pricingPlans from "../../../Assets/Images/pricing-plans.png";
import {PlanData} from "../../../App";

// payment page layout component for display available payment methods
const PaymentsPage = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const {plans} = useContext(PlanData);
  const isCardDetailsCardDisabled = useMemo(() => plans.name === 'free', [plans.name]);
  const cardDetailsCard = <SettingElementCard
    image={addCard}
    firstLineText="Card"
    secondLineText="Details"
    onClickUrl="card"
    proPlan={true}
    disabled={isCardDetailsCardDisabled}
    onClick={() => setTooltipVisible(true)}
  />;

  return (
    <>
      <div className={styles.paymentsPage_element}>
        <Row wrap={true}>
          {isCardDetailsCardDisabled ? (
            <Tooltip
              color='orange'
              title='Please upgrade your plan!'
              onVisibleChange={setTooltipVisible}
              defaultVisible={false}
              visible={tooltipVisible}
            >
              {cardDetailsCard}
            </Tooltip>
          ) : cardDetailsCard}

          <SettingElementCard
            image={pricingPlans}
            firstLineText="Plans"
            secondLineText="Details"
            onClickUrl="plans"
          />
        </Row>
      </div>
    </>
  );
};

export default PaymentsPage;
