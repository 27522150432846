import React, { useContext, useState } from "react";
import {Form, Button, Typography, Select, Tooltip, Space} from "antd";
import styles from "./Step1.module.less";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AcsData, PlanData } from "../../../../App";
import {useNavigate} from "react-router-dom";
const { Text } = Typography;
const { Option } = Select;

const Step1 = () => {
  const navigate = useNavigate();
  const { settingData, customerType } = useContext(AcsData);
  const { plans } = useContext(PlanData);

  const { currencies, supportedCurrencies, storeCurrency } = settingData;
  // console.log(storeCurrency);

  // this function use for get currencyArray in proper format (ex :- INR - INDIA ) and sorted array also
  const getCurrenciesArray = (currenciesObj) => {
    const currenciesArray = Object.keys(currenciesObj).map(
      (key) => `${key}-${currenciesObj[`${key}`]}`
    );
    return currenciesArray.sort();
  };

  // this function use for get all SupportedCurrencies in proper array format
  const getSupportedCurrenciesArray = (supportedCurrencies) => {
    const supportedCurrenciesArray = supportedCurrencies.split(" ");
    // console.log("supportedCurrencies -", supportedCurrenciesArray);
    const newSupportedCurrenciesArray = [];
    Object.keys(currencies).forEach((key) =>
      newSupportedCurrenciesArray.push({
        key: key,
        value: currencies[key],
      })
    );

    // can we remove it ????......
    const updatedSupportedCurrenciesArray = [];

    for (let i = 0; i < newSupportedCurrenciesArray.length; i++) {
      for (let j = 0; j < supportedCurrenciesArray.length; j++) {
        if (
          newSupportedCurrenciesArray[i].key === supportedCurrenciesArray[j]
        ) {
          const element = `${supportedCurrenciesArray[j]}-${newSupportedCurrenciesArray[i].value}`;
          updatedSupportedCurrenciesArray.push(element);
        }
      }
    }

    return updatedSupportedCurrenciesArray.sort();
  };

  const [currenciesArray, setCurrenciesArray] = useState(
    getCurrenciesArray(currencies)
  );
  const [updatedSupportedCurrenciesArray, setUpdatedSupportedCurrenciesArray] =
    useState(getSupportedCurrenciesArray(supportedCurrencies));

  const [updatedSupportedCurrencies, setUpdatedSupportedCurrencies] =
    useState(supportedCurrencies);

  return (
    <>
      <Form.Item
        dependencies={["supportedCurrencies"]}
        className={styles.stepOne_CurrenciesHeader}
      >
        {({ getFieldValue, setFieldsValue, validateFields }) => {
          // function for select all currencies
          const selectAllCurrencies = () => {
            setFieldsValue({
              supportedCurrencies: Object.keys(currencies),
            });

            setUpdatedSupportedCurrencies(
              getFieldValue("supportedCurrencies").join(" ")
            );

            setUpdatedSupportedCurrenciesArray(
              getSupportedCurrenciesArray(Object.keys(currencies).join(" "))
            );
            // noinspection JSIgnoredPromiseFromCall
            validateFields(['supportedCurrencies']);
          };

          // function for remove all currencies but in this case we cannot remove storeCurrency
          const removeAllCurrencies = () => {
            setFieldsValue({
              supportedCurrencies: [storeCurrency],
              defaultCurrency: storeCurrency,
            });

            setUpdatedSupportedCurrenciesArray(
              getSupportedCurrenciesArray(storeCurrency)
            );
          };

          // this function call when someone de selected a currency form selectbox
          const onDeselectCurrencies = (value) => {
            if (getFieldValue("defaultCurrency") === value) {
              setFieldsValue({
                defaultCurrency: storeCurrency,
              });
            }
          };

          // this function call when someone type something in the selectbox and search currency
          const onSearch = (inputValue, option) => {
            return option.children
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase());
          };

          const myCurrencyTooltip = (
            <div>
              Currencies of various locations that the foreign customer will see
              locally will be added in this section.
            </div>
          );

          return (
            <>
              <div className={styles.header}>
                <Text className={styles.title_text}>
                  My Currencies
                  <Tooltip trigger='click' placement="top" title={myCurrencyTooltip}>
                    <QuestionCircleOutlined
                      className={styles.tooltipIcon}
                    />
                  </Tooltip>
                </Text>
                <div className={styles.btn_wrapper}>
                  <Button
                    className={styles.header_btn}
                    type="link"
                    onClick={selectAllCurrencies}
                  >
                    Select all
                  </Button>
                  <Text>/</Text>
                  <Button
                    className={styles.header_btn}
                    type="link"
                    onClick={removeAllCurrencies}
                  >
                    Remove all
                  </Button>
                </div>
              </div>

              {/* <Text className={styles.stepOne_text}>
        Default currency is the currency in which your prices will be displayed
        when a customer opens to your store.
      </Text> */}
              <Form.Item
                name="supportedCurrencies"
                className={styles.supportedCurrenciesFormItem}
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                  {
                    validator(_, value) {
                      if (
                        customerType.customerType === "new" &&
                        plans.name === "free" &&
                        value.length > 3 &&
                        value.length > (settingData.supportedCurrencies?.split(' ') ?? []).length
                      ) {
                        return Promise.reject(
                          <span>
                            Please upgrade to paid plan from <Button
                              className={styles.pricingPageLinkBtn}
                              type='link'
                              onClick={() => {
                                if (!settingData.isSetupCompleted) {
                                  sessionStorage.setItem('previouslySelectedCurrencies', JSON.stringify(value));
                                }
                                navigate(settingData.isSetupCompleted ? '/acs/subscription/plans' : '/pricing-plan', {
                                  state: { shouldRedirect: false }
                                })
                              }}
                            >here</Button>{' '}
                            to use more than 3 currencies.</span>)
                      }

                      return Promise.resolve()
                    }
                  }
                ]}
                // normalize function call when something is change in this form field
                normalize={(supportedCurrencies) => {
                  // this use for get updated supportedCurrencies
                  // update supportedCurrencies Array

                  setUpdatedSupportedCurrenciesArray(
                    getSupportedCurrenciesArray(supportedCurrencies.join(" "))
                  );

                  // update supportedCurrencies String
                  setUpdatedSupportedCurrencies(supportedCurrencies.join(" "));

                  // console.log("152", supportedCurrencies);

                  // console.log(getFieldValue("defaultCurrency"));

                  if (!supportedCurrencies.includes("USD")) {
                    // i think we put here storecurrency not USD but i am not sure
                    setFieldsValue({
                      defaultCurrency: supportedCurrencies[0],
                    });
                  }

                  return supportedCurrencies;
                }}
              >
                <Select
                  mode="multiple"
                  size="large"
                  style={{ width: "100%" }}
                  onDeselect={onDeselectCurrencies}
                  placeholder="Please select"
                  filterOption={onSearch}
                  className={styles.stepOne_currenciesSelectBox}
                >
                  {currenciesArray.map((currencies, index) => {
                    const currenciesValue = currencies.split("-");

                    return (
                      <Option
                        disabled={currenciesValue[0] === storeCurrency}
                        key={currencies}
                        value={currenciesValue[0]}
                      >
                        {currencies}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>

      {/* 2nd ele */}
      <Form.Item
        dependencies={["defaultCurrency"]}
        className={styles.stepOne_CurrenciesHeader}
      >
        {({ setFieldsValue, getFieldValue, validateFields }) => {
          // this function call when someone Click on the useStoreCurrency button
          const useStoreCurrency = () => {
            let updatedCurrencies;

            // if updated currencies not includes "USD" then includes
            if (updatedSupportedCurrencies.includes("USD")) {
              updatedCurrencies = updatedSupportedCurrencies;
            } else {
              // eslint-disable-next-line no-useless-concat
              updatedCurrencies = updatedSupportedCurrencies + " " + "USD";
            }

            setUpdatedSupportedCurrencies(updatedCurrencies);
            let updatedCurrenciesArray = updatedCurrencies.split(" ");

            // setDefaultCurrencies is storeCurrency
            setFieldsValue({
              defaultCurrency: storeCurrency,
              supportedCurrencies: updatedCurrenciesArray,
            });

            // updated currencies array also
            setUpdatedSupportedCurrenciesArray(
              getSupportedCurrenciesArray(updatedCurrencies)
            );
            // noinspection JSIgnoredPromiseFromCall
            validateFields(['supportedCurrencies']);
          };

          const myDefaultCurrencyTooltip = (
            <div>
              This currency will be shown to customers of the regions whose
              currency is not added in My Currencies
            </div>
          );

          return (
            <>
              <div className={styles.header}>
                <Space direction='vertical' size={0}>
                  <Text className={styles.title_text}>
                    My Default Currency
                    <Tooltip trigger='click' placement="top" title={myDefaultCurrencyTooltip}>
                      <QuestionCircleOutlined
                        className={styles.tooltipIcon}
                      />
                    </Tooltip>
                  </Text>
                  <Text style={{ fontSize: '0.8rem' }}>
                    (For Rest Of The World)
                  </Text>
                </Space>
                <div className={styles.btn_wrapper}>
                  <Button
                    className={styles.header_btn}
                    type="link"
                    onClick={useStoreCurrency}
                  >
                    Use store currency
                  </Button>
                </div>
              </div>
              {/*<Text className={styles.stepOne_text}>*/}
              {/*  Default currency is the currency in which your prices will be*/}
              {/*  displayed when a customer opens to your store.*/}
              {/*</Text>*/}
            </>
          );
        }}
      </Form.Item>
      <Form.Item
        name="defaultCurrency"
        rules={[
          {
            required: true,
            message: "Required",
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: "100%" }}
          placeholder="Please select"
          className={styles.stepOne_currenciesSelectBox}
        >
          {updatedSupportedCurrenciesArray.map((currencies, index) => {
            const currenciesValue = currencies.split("-");
            return (
              <Option key={index} value={currenciesValue[0]}>
                {currencies}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export default Step1;
