import React from "react";
import { Spin } from "antd";

// component for display spinner in Loading state
const Loading = () => {
  const spinnerStyles = {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <>
      <Spin size="large" style={spinnerStyles} />
    </>
  );
};

export default Loading;
