import React, {useState, useContext, useEffect, useRef} from "react";
import styles from "./Step3.module.less";
import {
  Form,
  Checkbox,
  Typography,
  Row,
  Col,
  Input,
  Popover,
  Button,
} from "antd";

import CustomChromePicker from "../../CustomComponent/CustomChromePicker/CustomChromePicker";
import CustomSelectbox from "../../CustomComponent/CustomSelectbox/CustomSelectbox";
import CustomPopover from "../../CustomComponent/CustomPopover/CustomPopover";
import { AcsData } from "../../../../App";


const { Text } = Typography;

const initialAdvancedSettingOpen = true;

const Step3 = () => {
  const oldAdvancedSettingRef = useRef(initialAdvancedSettingOpen);
  const advancedSettingBtnRef = useRef();
  // textColor field state
  const [textColorPopover, setTextColorPopover] = useState(false);
  // textHoverColor state
  const [textHoverColorPopover, setTextHoverColorPopover] = useState(false);
  const [bgColorPopover, setBgColorPopover] = useState(false);
  const [bgHoverColorPopover, setBgHoverColorPopover] = useState(false);
  const [advanceSetting, setAdvanceSetting] = useState(initialAdvancedSettingOpen);

  const { globalState } = useContext(AcsData);

  useEffect(() => {
    if (!advanceSetting) {
      oldAdvancedSettingRef.current = false;
      advancedSettingBtnRef.current?.scrollIntoView({
        block: 'end',
        behavior: 'smooth'
      });
      return;
    }

    if (
      oldAdvancedSettingRef.current !== false ||
      !advancedSettingBtnRef.current
    ) {
      return;
    }

    oldAdvancedSettingRef.current = true;
    advancedSettingBtnRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [advanceSetting])
  // console.log("step3 render", plans);

  return (
    <>
      <div className={styles.stepThree_wrapper}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xs={24} lg={12} xl={12} md={12} sm={12}>
            <div className={styles.stepThree_showFlagContainer}>
              <Form.Item name="displayFlag" valuePropName="checked">
                <Checkbox
                  // disabled={
                  //   plans.name === "free" ||
                  //   (customerType.customerType === "new" &&
                  //     plans.name === "free")
                  // }
                  onChange={(e) => {
                    globalState({
                      displayFlag: e.target.checked,
                    });
                  }}
                >
                  <Text strong className={styles.stepThree_text}>
                    Show flag
                  </Text>
                </Checkbox>
                {/* <CustomCheckbox /> */}
              </Form.Item>
              {/*<ProTagPopover />*/}
            </div>
          </Col>

          <Col className="gutter-row" xs={24} lg={12} xl={12} md={12} sm={12}>
            <Form.Item name="displayFlag">
              <CustomSelectbox />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xs={24} lg={12} xl={12} md={12} sm={12}>
            <Form.Item name="borderLook" valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  globalState({
                    borderLook: e.target.checked,
                  });
                }}
              >
                <Text strong className={styles.stepThree_text}>
                  Show Border
                </Text>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} lg={12} xl={12} md={12} sm={12}>
            <Form.Item name="borderLook">
              <CustomPopover />
            </Form.Item>
          </Col>
        </Row>

        <Button
          ref={advancedSettingBtnRef}
          type="link"
          size="large"
          className={styles.stepThree_advancedLinkBtn}
          onClick={() => setAdvanceSetting(!advanceSetting)}
        >
          Advanced Customization
          <i
            className="las la-long-arrow-alt-right"
            style={{ marginLeft: "5px" }}
          ></i>
        </Button>

        {advanceSetting && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              className="gutter-row"
              xs={24}
              lg={12}
              xl={12}
              md={12}
              sm={12}
            >
              <Popover
                content={
                  <Form.Item name="textColor">
                    <CustomChromePicker fieldName="textColor" />
                  </Form.Item>
                }
                trigger="click"
                visible={textColorPopover}
                onVisibleChange={() => setTextColorPopover(!textColorPopover)}
              >
                <Form.Item dependencies={["textColor"]} label="Text Color">
                  {({ getFieldValue }) => (
                    <Input
                      // name="textColor"
                      size="large"
                      placeholder="select color"
                      value={getFieldValue("textColor")}
                      suffix={
                        <span
                          className={styles.color_demo}
                          style={{ background: getFieldValue("textColor") }}
                        ></span>
                      }
                    />
                  )}
                </Form.Item>
              </Popover>
            </Col>
            {/* sjkdksdl */}
            <Col
              className="gutter-row"
              xs={24}
              lg={12}
              xl={12}
              md={12}
              sm={12}
            >
              <Popover
                content={
                  <Form.Item name="textHoverColor">
                    <CustomChromePicker />
                  </Form.Item>
                }
                trigger="click"
                visible={textHoverColorPopover}
                onVisibleChange={() =>
                  setTextHoverColorPopover(!textHoverColorPopover)
                }
              >
                <Form.Item
                  dependencies={["textHoverColor"]}
                  label="Text Hover Color"
                >
                  {({ getFieldValue }) => (
                    <Input
                      size="large"
                      placeholder="select color"
                      value={getFieldValue("textHoverColor")}
                      suffix={
                        <span
                          className={styles.color_demo}
                          style={{
                            background: getFieldValue("textHoverColor"),
                          }}
                        ></span>
                      }
                    />
                  )}
                </Form.Item>
              </Popover>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              lg={12}
              xl={12}
              md={12}
              sm={12}
            >
              <Popover
                content={
                  <Form.Item name="backgroundColor">
                    <CustomChromePicker />
                  </Form.Item>
                }
                trigger="click"
                visible={bgColorPopover}
                onVisibleChange={() => setBgColorPopover(!bgColorPopover)}
              >
                <Form.Item
                  dependencies={["backgroundColor"]}
                  label="Background color"
                >
                  {({ getFieldValue }) => (
                    <Input
                      size="large"
                      placeholder="select color"
                      value={getFieldValue("backgroundColor")}
                      suffix={
                        <span
                          className={styles.color_demo}
                          style={{
                            background: getFieldValue("backgroundColor"),
                          }}
                        ></span>
                      }
                    />
                  )}
                </Form.Item>
              </Popover>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              lg={12}
              xl={12}
              md={12}
              sm={12}
            >
              <Popover
                content={
                  <Form.Item name="backgroundHoverColor">
                    <CustomChromePicker />
                  </Form.Item>
                }
                trigger="click"
                visible={bgHoverColorPopover}
                onVisibleChange={() =>
                  setBgHoverColorPopover(!bgHoverColorPopover)
                }
              >
                <Form.Item
                  dependencies={["backgroundHoverColor"]}
                  label="Background Hover Color"
                >
                  {({ getFieldValue }) => (
                    <Input
                      size="large"
                      placeholder="select color"
                      value={getFieldValue("backgroundHoverColor")}
                      suffix={
                        <span
                          className={styles.color_demo}
                          style={{
                            background: getFieldValue("backgroundHoverColor"),
                          }}
                        ></span>
                      }
                    />
                  )}
                </Form.Item>
              </Popover>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Step3;
