import axios from "axios";

// function for axios put call
const putCall = async (url, data) => {
  // https://f3a5-103-244-121-9.ngrok.io/settings

  try {
    const res = await axios.put(url, data);

    // console.log(res.data);
    if (res.data) {
      return {
        status: 200,
        data: res.data,
      };
    }
  } catch (error) {
    // console.log("somthing went wrong put call ", error);

    return {
      // status: error.response.status,
      // error: error.response.data.message,
      status: 401,
      error: "Something went wrong",
    };
  }
};

export default putCall;
