import React from "react";
import styles from "./StepFour.module.less";

import Preview from "../../Shared/Preview/Preview";
import Step4 from "../../Shared/Steps/Step4/Step4";

// step 4 component (pricing settings)
const StepFour = () => {
  return (
    <>
      <div className={styles.modalBody_wrapper}>
        <div className={styles.modalBody_leftSide}>
          <Step4 />
        </div>
        <div className={styles.modalBody_rightSide}>{/* <Preview /> */}</div>
      </div>
    </>
  );
};

export default StepFour;
