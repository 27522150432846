import React, { useContext } from "react";
import { Form, Select } from "antd";
import { AcsData } from "../../../../App";
const { Option } = Select;

const CustomSelectbox = (props) => {
  // console.log("custom select box", props);
  const { value } = props;
  const { globalState } = useContext(AcsData);
  return (
    <>
      <Form.Item name="flagStyle">
        <Select
          size="large"
          disabled={!value && true}
          onChange={(value) => {
            // console.log("selectbox value", value);
            globalState({
              flagStyle: value,
            });
          }}
        >
          <Option value="rectangle">Rectangle</Option>
          <Option value="circle">Circle</Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default CustomSelectbox;
