import React from "react";
import styles from "./SettingElementCard.module.less";
import { Card, Space, Typography, Col } from "antd";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

const { Text } = Typography;

// settings card element component which are displayed all settings cards UI according to props in setting page
const SettingElementCard = ({
  image,
  firstLineText,
  secondLineText,
  proPlan,
  onClickUrl,
  disabled = false,
  onClick,
  ...props
}, ref) => {
  const navigate = useNavigate();
  const navigateToOnClickUrl = !disabled
    ? (() => navigate(onClickUrl))
    : undefined;

  return (
    <>
      {proPlan ? (
        <Col
          {...props}
          ref={ref}
          // span={5}
          className={styles.settingPage_colum}
          xs={24}
          sm={10}
          md={8}
          lg={5}
          xl={5}
        >
          <div className={classNames(disabled && styles.disabledLink)} onClick={() => {
            onClick?.();
            navigateToOnClickUrl?.();
          }}>
            {/*<Badge count={"Pro"} color="purple">*/}
            <Card className={styles.settingElement_card}>
              <Space direction="vertical">
                <img
                  className={styles.settingCard_img}
                  src={image}
                  alt={image}
                />
                <Space direction="vertical" size={0}>
                  <Text>{firstLineText}</Text>
                  <Text>{secondLineText}</Text>
                </Space>
              </Space>
            </Card>
            {/*</Badge>*/}
          </div>
        </Col>
      ) : (
        <Col
          {...props}
          ref={ref}
          // span={5}
          className={styles.settingPage_colum}
          xs={24}
          sm={10}
          md={8}
          lg={5}
          xl={5}
        >
          <div className={classNames(disabled && styles.disabledLink)} onClick={() => {
            onClick?.();
            navigateToOnClickUrl?.();
          }}>
            <Card className={styles.settingElement_card}>
              <Space direction="vertical">
                <img
                  className={styles.settingCard_img}
                  src={image}
                  alt={image}
                />
                <Space direction="vertical" size={0}>
                  <Text>{firstLineText}</Text>
                  <Text>{secondLineText}</Text>
                </Space>
              </Space>
            </Card>
          </div>
        </Col>
      )}
    </>
  );
};

const SettingElementCardWithRef = React.forwardRef(SettingElementCard);

export default SettingElementCardWithRef;
