import axios from "axios";

// function for axios post call
const postCall = async (url, data) => {
  // https://f3a5-103-244-121-9.ngrok.io/settings

  // console.log("i am in post call ");
  try {
    const res = await axios.post(url, data);

    // console.log(res.data);
    if (res.data) {
      return {
        status: 200,
        data: res.data,
      };
    }
  } catch (error) {
    // console.log("somthing went wrong ", error.response.status);
    return {
      // status: error.response.status,
      // error: error.response.data.message,
      status: 401,
      error: error,
    };
  }
};

export default postCall;
