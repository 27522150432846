import React, { useState } from "react";
import styles from "./MainPage.module.less";
import {Layout, Row, Col, Drawer, Button} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

import NavBarMenu from "../../Shared/NavBarMenu/NavBarMenu";
import Logo from "../../Shared/Logo/Logo";
import { Outlet } from "react-router-dom";

const { Header, Content, Sider } = Layout;

// main layout component which are rendered dashboard , settings and help setion according to url
const MainPage = () => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const MenuIcon = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;

  return (
    <>
      <Layout>
        {/* for responsicve */}
        {/* sidebar for smaller screens */}
        {/*<Sider*/}
        {/*  trigger={null}*/}
        {/*  collapsible*/}
        {/*  collapsed={collapsed}*/}
        {/*  className={*/}
        {/*    collapsed ? styles.sidebar_collapsed : styles.sidebar_notcollapsed*/}
        {/*  }*/}
        {/*>*/}
        {/*  {!collapsed ? <Logo sidebar={true} /> : null}*/}

        {/*  <NavBarMenu theme="light" mode="inline" sidebar={true} toggle={toggle} />*/}
        {/*</Sider>*/}
        <Drawer
          closable={false}
          contentWrapperStyle={{ maxWidth: '90vw' }}
          // title={<Logo sidebar={true} />}
          placement='left'
          visible={!collapsed}
          onClose={toggle}
        >
          <Button icon={<MenuFoldOutlined />} style={{ position: 'absolute' }} onClick={toggle} />
          <Logo sidebar={true} />
          <NavBarMenu theme="light" mode="inline" sidebar={true} toggle={toggle} className={styles.drawerMenu} />
        </Drawer>

        <Layout className={styles.settingPage_wrapper}>
          {/* // for responsive */}
          <Header
            // className="site-layout-background"
            style={{ padding: 0 }}
            className={styles.settingPage_header_responsive}
          >
            <MenuIcon className='trigger' onClick={toggle} />
          </Header>
          <Header className={styles.settingPage_header}>
            <Row justify="center">
              <Col span={7} className={styles.settingPage_menuColum}>
                <Logo />
              </Col>
              <Col
                span={10}
                className={`${styles.settingPage_menuColum} ${styles.settingPage_col_border_bouttom}`}
              >
                <NavBarMenu
                  // selectedKeys="1"

                  mode="horizontal"
                  sidebar={false}
                />
              </Col>
              <Col
                span={7}
                className={styles.settingPage_menuColum}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <SketchOutlined className={styles.reviewIcon} /> */}
                {/* google Translate */}
              </Col>
            </Row>
          </Header>
          <Content style={{ background: "#fff" }}>
            {/* // Outlet component rendered here according to url */}
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MainPage;
