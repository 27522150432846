export const MONTHLY_PLAN_TYPE = "monthly";
export const YEARLY_PLAN_TYPE = 'yearly';

export const freeMonthlyPlan = {
  name: "free",
  status: "active",
  price: "0",
  originalPrice: "0",
  type: MONTHLY_PLAN_TYPE,
}

export const freeYearlyPlan = {
  ...freeMonthlyPlan,
  type: YEARLY_PLAN_TYPE
};

export const premiumMonthlyPlan = {
  name: "premium",
  status: "active",
  price: "9.99",
  originalPrice: "29",
  type: MONTHLY_PLAN_TYPE,
};

export const premiumYearlyPlan = {
  ...premiumMonthlyPlan,
  price: "83.88",
  originalPrice: "250",
  type: YEARLY_PLAN_TYPE,
}