import React from "react";
import styles from "./Logo.module.less";
import { Space, Typography } from "antd";
import logo from "../../../Assets/Images/logo.png";

// logo component
const Logo = ({ sidebar }) => {
  const logoClass = sidebar ? styles.logoSideBar : styles.logo;
  return (
    <>
      <div className={logoClass}>
        <img src={logo} alt="logo" style={{ width: "40px", height: "40px" }} />
        <div className={styles.logoText}>Multi Currency Converter</div>
      </div>
    </>
  );
};

export default Logo;
