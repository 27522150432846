import React from "react";
import ModalComponent from "../../Shared/ModalComponent/ModalComponent";

import styles from "./PricingSetting.module.less";

import ModalHeader from "../../Shared/ModalHeader/ModalHeader";

import Preview from "../../Shared/Preview/Preview";
import Step4 from "../../Shared/Steps/Step4/Step4";

// pricing settings component which display after onboarding in settings section

const PricingSetting = () => {
  // console.log("CurrencySetting render");

  return (
    <>
      <ModalComponent
        header={
          <ModalHeader
            heading="Pricing Setting"
            sunHeading="Let's customize the product prices after conversion like rounding the proper digits or hiding the decimal places so that your customers will always see the cleaner prices"
          />
        }
        footer={true}
      >
        <div className={styles.modalBody_wrapper}>
          <div className={styles.modalBody_leftSide}>
            <Step4 />
          </div>
          <div className={styles.modalBody_rightSide}>{/* <Preview /> */}</div>
        </div>
      </ModalComponent>
    </>
  );
};

export default PricingSetting;
