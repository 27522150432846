import React from "react";
import styles from "./DashboardPage.module.less";
import { Card, Space, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

// DashboardPage component
const DashboardPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.dashboardPage_wrapper}>
        <div className={styles.dashboardPage_card_wrapper}>
          <Card className={styles.dashboardPage_card}>
            <Space direction="vertical" size={20}>
              <div className={styles.heading_wrapper}>
                <Text strong className={styles.heading}>
                  Go International!
                </Text>
              </div>
              <Text className={styles.normal_text}>
                <Text strong className={styles.bold_text}>
                  Congratulations!
                </Text>
                Multi Currency Converter app is running successfully on your
                store. Now your international customers can see the prices in
                their local currencies.
              </Text>
              <Text className={styles.normal_text}>
                Are you looking to customize the look and feel of you currency
                switcher? You can go to
                <Button
                  type="link"
                  className={styles.linkBtn}
                  style={{ marginLeft: 2, marginRight: 2 }}
                  onClick={() => {
                    navigate("/acs/setting");
                  }}
                >
                  settings
                </Button>
                tab and do it yourself, or send a mail us at
                <Button
                  type="link"
                  className={styles.linkBtn}
                  style={{ paddingLeft: 2 }}
                  // open mailbox when click on this link
                  onClick={() => {
                    window.location.href = "mailto:support@mlveda.com";
                  }}
                >
                  support@mlveda.com
                </Button>{" "}
                and our awesome support team will help you out. You
                can also watch the following video to learn more about the setup.
              </Text>
              <Text className={styles.normal_text}>
                Happy Selling Globally!
              </Text>
              <iframe className={styles.setupVideo} src="https://www.youtube.com/embed/16XPy6mY22Q"
                      title="Multi Currency Converter app demo" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </Space>
          </Card>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
