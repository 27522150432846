import React from "react";
import ModalComponent from "../../Shared/ModalComponent/ModalComponent";

import styles from "./CurrencySetting.module.less";

import ModalHeader from "../../Shared/ModalHeader/ModalHeader";

import Step1 from "../../Shared/Steps/Step1/Step1";
import Preview from "../../Shared/Preview/Preview";

// currency settings component which display after onboarding in settings section
const CurrencySetting = () => {
  // console.log("CurrencySetting render");

  return (
    <>
      <ModalComponent
        header={
          <ModalHeader
            heading="Currencies Settings"
            sunHeading="Let's choose the currencies that you want to choose in your Shopify store out of 175+ currencies"
          />
        }
        footer={true}
      >
        <div className={styles.modalBody_wrapper}>
          <div className={styles.modalBody_leftSide}>
            <Step1 />
          </div>
          <div className={styles.modalBody_rightSide}>{/* <Preview /> */}</div>
        </div>
      </ModalComponent>
    </>
  );
};

export default CurrencySetting;
