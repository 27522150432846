import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
import "./Http/axios";
import { Routes, Route, Navigate } from "react-router-dom";
import { message, Result, Button, Typography, Modal } from "antd";
import StepFinal from "./Componentes/Onboarding/StepFinal/StepFinal";
import StepOne from "./Componentes/Onboarding/StepOne/StepOne";
import StepThree from "./Componentes/Onboarding/StepThree/StepThree";
import StepTwo from "./Componentes/Onboarding/StepTwo/StepTwo";
import WelcomePage from "./Componentes/Pages/WelcomePage/WelcomePage";
import SettingPage from "./Componentes/Pages/SettingPage/SettingPage";
import DashboardPage from "./Componentes/Pages/DashboardPage/DashboardPage";
import HelpPage from "./Componentes/Pages/HelpPage/HelpPage";
import MainPage from "./Componentes/Pages/MainPage/MainPage";
import CurrencySetting from "./Componentes/Settings/CurrencySetting/CurrencySetting";
import DesignSetting from "./Componentes/Settings/DesignSetting/DesignSetting";
import LocationSetting from "./Componentes/Settings/LocationSetting/LocationSetting";
import OnboardingModalComponent from "./Componentes/Onboarding/OnboardingModalComponent/OnboardingModalComponent";
import PricingSetting from "./Componentes/Settings/PricingSetting/PricingSetting";
import StepFour from "./Componentes/Onboarding/StepFour/StepFour";

import getCall from "./Http/getCall";
import Loading from "./Componentes/Shared/Loading/Loading";
import NotFoundPage from "./Componentes/Shared/NotFoundPage/NotFoundPage";
import PaymentsPage from "./Componentes/Pages/PaymentsPage/PaymentsPage";
import CardPayment from "./Componentes/Payments/CardPayment/CardPayment";
import UpgradePlanPage from "./Componentes/Pages/UpgradePlanPage/UpgradePlanPage";
import ProtectedRoute, {
  ProtectedRouteOnBording,
} from "./Componentes/Shared/ProtectedRoute/ProtectedRoute";

const { Text } = Typography;

export const AcsData = createContext(); // context for acs setting data
export const PlanData = createContext(); // context for plan (free or premium) data

message.config({
  top: 80,
});

const App = () => {
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "9a70b14e-b8db-48b2-8773-95eb66b91cee";
    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
      window.$crisp.push(["set", "session:segments", [["thinkific-MCC"]]]);
    })();
  }, [])
  // const location = useLocation();

  // globel state for setting data
  const [settingData, setData] = useState({
    // appPlan: "free",
    // stepReached: 0,
    // appCmpPrice: 9.99,
    // enable: true,
    // appEnable: true,
    // subdomain: "abc.thikific.com",
    // appPrice: 9.99,
    // themeName: "Debut-removeFrom",
    // isSetupCompleted: true,
    // storeCurrency: "USD",
    // isThemeSupported: true,
    // supportedCurrencies: "USD EUR CAD AUD SGD",
    // defaultCurrency: "USD",
    // positionValue: "top_with_right",
    // currencyFormat: "with",
    // displayFlag: false,
    // // new
    // displayPrice: false,
    // position: "fixed",
    // currencies: {
    //   ARS: "Argentine Peso",
    //   DZD: "Algerian Dinar",
    //   AFN: "Afghanistan Afghani",
    //   AMD: "Armenian Dram",
    // },
    // flagStyle: "circle",
    // borderLook: false,
    // borderLineColor: "#bdb5b5",
    // textColor: "#1c1b1b",
    // textHoverColor: "#bf4714",
    // backgroundColor: "#f3f0f0",
    // backgroundHoverColor: "#fffafa",
    // autoSwitch: false,
    // hideDecimal: false,
    // roundDecimalValue: 1,
    // roundDecimal: false,
    // positionBottom: "20px",
    // positionLeft: "30px",
    // positionRight: "25px",
    // positionTop: "10px",
  });

  console.log("i am in ACS-test branch");

  // globel state for plans
  const [plans, setPlans] = useState({});

  // console.log("app", settingData, plans);

  // globel state for session
  const [session, setSession] = useState(true);
  const [loading, setLoading] = useState(true);

  // it's use for find difference between old and new customer
  const [customerType, setCustomerType] = useState({});

  // function use for update globel state
  const globalState = (newState) => {
    setData({
      ...settingData,
      ...newState,
    });
  };

  // function use for find token form url
  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;

    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log("outside if customerType", customerType.customerType);

    const fn = async () => {
      // localhost:3000/?token="" // use for get token field value form url
      const token = getParameterByName("token"); // get token form URL

      if (token != null) {
        sessionStorage.setItem("token", token); // store token in sessionStorage
      } else if (
        (process.env.REACT_APP_ENVIORNMENT === "production" ||
          process.env.REACT_APP_ENVIORNMENT === "development") &&
        !sessionStorage.getItem("token")
      ) {
        setSession(false);
        setLoading(false);
        return;
      }

      // response intersepter for find that session is expired or not if session expired or we cannot get token so here we resive 401 error
      axios.interceptors.response.use(
        (response) => response,
        (error) =>
          new Promise((resolve, reject) => {
            if (error.response && error.response.status === 401) {
              // Set session false when token expire
              setSession(false);
              setLoading(false);
              // resolve(error);
            }
            // console.log(error.response);
            reject(error);
          })
      );

      await getCall("/shopSecret") // api call for get data of customer are old or new
        .then(async (res) => {
          if (res.status === 200) {
            setCustomerType(res.data);
            // console.log(116, res.data);
            if (res.data.customerType !== "old") {
              await getCall("/plans") // api call for plans (call this if customer are new)
                .then((res) => {
                  if (res.status === 200) {
                    setPlans(res.data);
                    // console.log(res);
                  } else {
                    console.log("something went wrong");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setSession(false);
                  setLoading(false);
                });
            }
          } else {
            console.log("something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
          setSession(false);
          setLoading(false);
        });
      // if (customerType.customerType !== "old") {
      //   console.log("if of customerType", customerType.customerType);

      // }

      // api call
      // const getAllSettingsDetails = async () => {
      //   const res = await getSettingData(token);
      //   return res;
      // };

      // api call
      await getCall("/settings") // get all app settinges
        .then((res) => {
          console.log("useEffext", res);
          if (res.status === 200 && res.data) {
            globalState(res.data);
            setLoading(false);
          } else {
            // console.log(res);
            if (!res.status === 401) {
              message.error(res.error);
            }
          }
        })
        .catch((error) => {
          setSession(false);
          setLoading(false);
        });

      // un comment when env === local
      // setLoading(false);

      // console.log("useEffext", res);
      // if (res.status === 200 && res.data) {
      //   globalState(res.data);
      //   setLoading(false);
      // } else {
      //   // console.log(res);
      //   if (!session) {
      //     message.error(res.error);
      //   }
      // }
    }

    // noinspection JSIgnoredPromiseFromCall
    fn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log();

  // function use for redirect routes according to stepReached
  const RedirectRoute = ({ stepReached, isSetupCompleted }) => {
    // console.log(stepReached, isSetupCompleted);
    if (
      stepReached === 0 &&
      isSetupCompleted === false &&
      customerType.customerType !== "old"
    ) {
      return <Navigate to="/welcome" />;
    }
    if (isSetupCompleted) {
      return <Navigate to="/acs/dashboard" />;
    } else {
      switch (stepReached) {
        case 0: {
          return <Navigate to="/welcome" />;
        }
        case 1: {
          return <Navigate to="/acs/setup/step-1" />;
        }
        case 2: {
          return <Navigate to="/acs/setup/step-2" />;
        }
        case 3: {
          return <Navigate to="/acs/setup/step-3" />;
        }
        case 4: {
          return <Navigate to="/acs/setup/step-4" />;
        }
        case 5: {
          return <Navigate to="/acs/setup/final-step" />;
        }
        default: {
          return <Navigate to="/welcome" />;
        }
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : session ? (
        <AcsData.Provider
          value={{
            settingData,
            globalState,
            setSession,
            customerType,
            setCustomerType,
          }}
        >
          <PlanData.Provider value={{ plans, setPlans }}>
            <Routes>
              <Route
                path="/"
                element={
                  <RedirectRoute
                    stepReached={settingData.stepReached}
                    isSetupCompleted={settingData.isSetupCompleted}
                  />
                }
              />
              <Route path="/welcome" element={<WelcomePage />} />
              <Route path="/pricing-plan" element={<UpgradePlanPage />}></Route>
              <Route
                path="/pricing-plan/card"
                element={
                  // <ProtectedRoute settings={settingData}>
                  <CardPayment />
                  // </ProtectedRoute>
                }
              ></Route>
              {/* routes for the onbordings section */}
              <Route path="/acs/setup" element={<OnboardingModalComponent />}>
                <Route
                  index
                  path="step-1"
                  element={
                    <ProtectedRouteOnBording settings={settingData}>
                      <StepOne />
                    </ProtectedRouteOnBording>
                  }
                />
                <Route
                  path="step-2"
                  element={
                    <ProtectedRouteOnBording settings={settingData}>
                      <StepTwo />
                    </ProtectedRouteOnBording>
                  }
                />
                <Route
                  path="step-3"
                  element={
                    <ProtectedRouteOnBording settings={settingData}>
                      <StepThree />
                    </ProtectedRouteOnBording>
                  }
                />
                <Route
                  path="step-4"
                  element={
                    <ProtectedRouteOnBording settings={settingData}>
                      <StepFour />
                    </ProtectedRouteOnBording>
                  }
                />
                <Route
                  path="final-step"
                  element={
                    <ProtectedRouteOnBording settings={settingData}>
                      <StepFinal />
                    </ProtectedRouteOnBording>
                  }
                />

                <Route
                  path="card"
                  element={
                    // <ProtectedRoute settings={settingData}>
                    <CardPayment />
                    // </ProtectedRoute>
                  }
                ></Route>
              </Route>
              {/* routes for dashboard settings help and other pages */}
              <Route path="/acs" element={<MainPage />}>
                <Route
                  index
                  path="dashboard"
                  element={
                    <ProtectedRoute settings={settingData}>
                      <DashboardPage />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="setting"
                  element={
                    <ProtectedRoute settings={settingData}>
                      <SettingPage />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="help"
                  element={
                    <ProtectedRoute settings={settingData}>
                      <HelpPage />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="subscription"
                  element={
                    <ProtectedRoute settings={settingData}>
                      <PaymentsPage />
                    </ProtectedRoute>
                  }
                ></Route>
              </Route>
              <Route path="/acs/subscription" element={<MainPage />}>
                <Route path="plans" element={<UpgradePlanPage />}></Route>
                <Route
                  path="card"
                  element={
                    // <ProtectedRoute settings={settingData}>
                    <CardPayment />
                    // </ProtectedRoute>
                  }
                ></Route>
              </Route>
              <Route path="/acs/setting" element={<MainPage />}>
                <Route
                  path="currencies"
                  element={
                    <ProtectedRoute settings={settingData}>
                      <CurrencySetting />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="location"
                  element={
                    <ProtectedRoute settings={settingData}>
                      <LocationSetting />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="design"
                  element={
                    <ProtectedRoute settings={settingData}>
                      <DesignSetting />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="pricing"
                  element={
                    <ProtectedRoute settings={settingData}>
                      <PricingSetting />
                    </ProtectedRoute>
                  }
                ></Route>
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </PlanData.Provider>
        </AcsData.Provider>
      ) : (
        <Modal visible={!session} closable={false} footer={null}>
          {/* session expired modal */}
          <Result
            status="403"
            title={<Text strong>Session Expired</Text>}
            subTitle={
              <Text>
                Your session expired. Please go to apps and open the app again.
              </Text>
            }
            // subdomain
            extra={
              settingData?.subdomain ? (
                <Button
                  href={`https://${settingData?.subdomain}/manage/apps`}
                  target="_blank"
                  type="primary"
                >
                  Redirect to Apps
                </Button>
              ) : null
            }
          />
        </Modal>
      )}
    </>
  );
};

export default App;
