import React, {useContext, useState} from "react";
import styles from "./Step2.module.less";
import { Form, Typography, Radio, Space, Row, Col, Button, Select } from "antd";
import laptopBackground from "../../../../Assets/Images/laptop-background.png";
import {AcsData, PlanData} from "../../../../App";

const { Text } = Typography;
const { Option } = Select;

// this function use for advancedCustomization section
// its return label and name field value which are used in form elements according to position of the switcher (ex:- if position is top_with_left then index == 1 means first select box so the label and name field set according to it)
const AdvancedCustomization = () => {
  const getSelectBoxLabelAndName = (index = 1, getFieldValue) => {
    if (getFieldValue("positionValue") === "top_with_left") {
      if (index === 1) {
        return {
          label: <Text strong>Spacing from Top</Text>,
          name: "positionTop",
        };
      } else {
        return {
          label: <Text strong>Spacing from Left</Text>,
          name: "positionLeft",
        };
      }
    } else if (getFieldValue("positionValue") === "top_with_right") {
      if (index === 1) {
        return {
          label: <Text strong>Spacing from Top</Text>,
          name: "positionTop",
        };
      } else {
        return {
          label: <Text strong>Spacing from Right</Text>,
          name: "positionRight",
        };
      }
    } else if (getFieldValue("positionValue") === "bottom_with_left") {
      if (index === 1) {
        return {
          label: <Text strong>Spacing from Bottom</Text>,
          name: "positionBottom",
        };
      } else {
        return {
          label: <Text strong>Spacing from Left</Text>,
          name: "positionLeft",
        };
      }
    } else {
      if (index === 1) {
        return {
          label: <Text strong>Spacing from Bottom</Text>,
          name: "positionBottom",
        };
      } else {
        return {
          label: <Text strong>Spacing from Right</Text>,
          name: "positionRight",
        };
      }
    }
  };

  return (
    <>
      <div className={styles.advancedCustomization_element}>
        <Form.Item dependencies={["positionValue"]}>
          {({ getFieldValue }) => {
            const firstSelectbox = getSelectBoxLabelAndName(1, getFieldValue);
            const secondSelectBox = getSelectBoxLabelAndName(2, getFieldValue);

            return (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col
                  className="gutter-row"
                  xs={24}
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                >
                  <Form.Item
                    label={firstSelectbox.label}
                    name={firstSelectbox.name}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Please select value"
                    >
                      <Option value="0px">0px</Option>
                      <Option value="5px">5px</Option>
                      <Option value="10px">10px</Option>
                      <Option value="15px">15px</Option>
                      <Option value="20px">20px</Option>
                      <Option value="25px">25px</Option>
                      <Option value="30px">30px</Option>
                      <Option value="35px">35px</Option>
                      <Option value="40px">40px</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                >
                  <Form.Item
                    label={secondSelectBox.label}
                    name={secondSelectBox.name}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Please select value"
                    >
                      <Option value="0px">0px</Option>
                      <Option value="5px">5px</Option>
                      <Option value="10px">10px</Option>
                      <Option value="15px">15px</Option>
                      <Option value="20px">20px</Option>
                      <Option value="25px">25px</Option>
                      <Option value="30px">30px</Option>
                      <Option value="35px">35px</Option>
                      <Option value="40px">40px</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            );
          }}
        </Form.Item>
      </div>
    </>
  );
};

const Step2 = () => {
  // this use for identifies which field are includes in pro plans and which are includes in free plans
  const { plans } = useContext(PlanData);
  const { customerType } = useContext(AcsData);
  const [showAdvancedSetting, setShowAdvancedSetting] = useState(false);
  return (
    <>
      <Form.Item name="position">
        <Radio.Group>
          <Radio size="large" value="fixed">
            <Space direction="vertical" size={0}>
              <Text strong className={styles.stepTwo_boldText}>
                Fixed position
              </Text>
              <Text className={styles.stepTwo_smallText}>
                Switcher will be fixed always at a selected position even on
                page scroll.
              </Text>
            </Space>
          </Radio>
          {/* fix position container */}
          <Form.Item dependencies={["position"]}>
            {({ getFieldValue }) =>
              getFieldValue("position") === "fixed" && (
                <>
                  <div className={styles.stepTwo_fixPosition_content}>
                    <Row gutter={16} justify="center">
                      <Col className={styles.left_colum} span={6}>
                        <Space direction="vertical" size={18}>
                          <Text className={styles.stepTwo_smallText}>
                            Top <br /> Left
                          </Text>
                          <Text className={styles.stepTwo_smallText}>
                            Bottom <br /> Left
                          </Text>
                        </Space>
                      </Col>
                      <Col span={10}>
                        <div className={styles.center}>
                          <img
                            className={styles.laptopBackground}
                            src={laptopBackground}
                            alt="laptopBackground"
                          />

                          <div className={styles.fixPosition_radioBtn_Wrapper}>
                            <Form.Item name="positionValue">
                              <Radio.Group>
                                <Radio
                                  size="large"
                                  value="top_with_left"
                                  className={styles.top_left_radioBtn}
                                ></Radio>
                                <Radio
                                  size="large"
                                  value="bottom_with_left"
                                  className={styles.bottom_left_radioBtn}
                                ></Radio>
                                <Radio
                                  size="large"
                                  value="top_with_right"
                                  className={styles.top_right_radioBtn}
                                ></Radio>
                                <Radio
                                  size="large"
                                  value="bottom_with_right"
                                  className={styles.bottom_right_radioBtn}
                                ></Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col span={6} className={styles.right_colum}>
                        <Space direction="vertical" size={18}>
                          <Text className={styles.stepTwo_smallText}>
                            Top <br /> Right
                          </Text>
                          <Text className={styles.stepTwo_smallText}>
                            Bottom <br /> Right
                          </Text>
                        </Space>
                      </Col>
                    </Row>
                    <div
                      className={styles.stepTwo_advancedCustomization_wrapper}
                    >
                      <Button
                        type="link"
                        size="large"
                        className={styles.stepTwo_advancedLinkBtn}
                        onClick={() =>
                          setShowAdvancedSetting(!showAdvancedSetting)
                        }
                      >
                        Advanced Customization
                        {showAdvancedSetting ? (
                          <i
                            className="las la-arrow-down"
                            style={{ marginLeft: "5px" }}
                          ></i>
                        ) : (
                          <i
                            className="las la-long-arrow-alt-right"
                            style={{ marginLeft: "5px" }}
                          ></i>
                        )}
                      </Button>

                      {showAdvancedSetting && <AdvancedCustomization />}
                    </div>
                  </div>
                </>
              )
            }
          </Form.Item>

          <Radio
            size="large"
            value="inline"
            // disabled={
            //   plans.name === "free" ||
            //   (customerType.customerType === "new" &&
            //     plans.name === "free")
            // }
            style={{ marginTop: "-20px" }}
          >
            <Space direction="vertical" size={0} style={{ color: "#E7E9EB" }}>
              <Text
                strong
                className={styles.stepTwo_boldText}
              >
                Inline with the Header{' '}
                {/*<ProTagPopover />*/}
              </Text>
              <Text
                className={styles.stepTwo_smallText}
              >
                This feature is supported default by all popular themes.
              </Text>
            </Space>
          </Radio>
          <Radio
            size="large"
            value="hide"
            className={styles.donnotShow_radioBtn}
          >
            <Space direction="vertical" size={0}>
              <Text strong className={styles.stepTwo_boldText}>
                Don't show at all
              </Text>
              <Text className={styles.stepTwo_smallText}>
                <ul type="disc">
                  <li>
                    In this option no Currency Converter will be displayed but
                    your prices will be converted as per your plan and settings.
                  </li>
                  {/* <li>
                    We suggest you to use this only if you are in paid plan.
                  </li> */}
                </ul>
              </Text>
            </Space>
          </Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default Step2;
