import React, { useContext } from "react";

import {Typography, Space, Button, message, Form} from "antd";
import styles from "./ModalFooter.module.less";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";


import { AcsData } from "../../../App";
import putCall from "../../../Http/putCall";
const { Text } = Typography;

// modal footer component
const ModalFooter = ({
  nextPage,
  onSave,
  setLoadingCancel,
  loadingCancel,
  closeModal,
  loadingSave,
  isBackBtn,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // get settings data and function for update settinges using context
  const { settingData, globalState, setSession } = useContext(AcsData);

  // console.log("is setup ", data.isSetupCompleted);

  // get token
  // let token = sessionStorage.getItem("token");

  // function handle back button click event when some one click on back button in onbording  modal
  const handleBack = async () => {
    // this function call api according to location and update setting in globel sate also
    setLoadingCancel(true);
    // console.log("location in onbording 1", location.pathname);
    if (location.pathname === "/pricing-plan/card") {
      setLoadingCancel(false);
      navigate("/pricing-plan");
    }

    if (location.pathname === "/acs/setup/step-4") {
      const stepdata = {
        stepReached: 3,
      };
      // let res = await setSettingData(stepdata);
      let res = await putCall("/settings", stepdata);
      // console.log("s3b", res);

      if (res.status === 200) {
        globalState(stepdata);
        setLoadingCancel(false);
        navigate("/acs/setup/step-3");
      } else {
        setSession(false);
      }
    } else if (location.pathname === "/acs/setup/step-3") {
      const stepdata = {
        stepReached: 2,
      };
      // let res = await setSettingData(stepdata);
      let res = await putCall("/settings", stepdata);
      // console.log("s2b", res);

      if (res.status === 200) {
        globalState(stepdata);
        setLoadingCancel(false);
        navigate("/acs/setup/step-2");
      } else {
        setSession(false);
      }
    } else if (location.pathname === "/acs/setup/step-2") {
      const stepdata = {
        stepReached: 1,
      };
      // let res = await setSettingData(stepdata);
      let res = await putCall("/settings", stepdata);
      // console.log("s1b", res);

      if (res.status === 200) {
        globalState(stepdata);
        setLoadingCancel(false);
        navigate("/acs/setup/step-1");
      } else {
        setSession(false);
      }
    } else if (location.pathname === "/acs/setup/step-1") {
      const stepdata = {
        stepReached: 0,
      };
      // let res = await setSettingData(stepdata);
      let res = await putCall("/settings", stepdata);
      // console.log("s0b", res);

      if (res.status === 200) {
        globalState(stepdata);
        setLoadingCancel(false);
        navigate("/welcome");
      } else {
        setSession(false);
      }
    }
  };

  return (
    <>
      {location.pathname === "/acs/setup/final-step" ? (
        <div className={styles.footerWrappper_for_finalStep}>
          <Text>
            If you find any issue, please send an email to
            <Button
              type="link"
              style={{ padding: 5 }}
              onClick={() => {
                window.location.href = "mailto:support@mlveda.com";
              }}
            >
              support@mlveda.com
            </Button>
            and we will get back to you within 24 hours.
          </Text>
        </div>
      ) : (
        <div className={styles.footerWrappper}>
          {/* save and cancel buttons use in settings page after onbording */}
          {/* next and cancel buttons use in onbording */}
          <Space size={20}>
            <Form.Item noStyle shouldUpdate>
              {(form) => {
                const isDisabled = form.getFieldsError().some(({errors}) => errors.length > 0)

                return (
                  settingData.isSetupCompleted ? (
                    <Form.Item noStyle shouldUpdate>
                      <Button
                        disabled={isDisabled}
                        type="primary"
                        className={styles.nextBtn}
                        onClick={onSave}
                        loading={loadingSave}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  ) : (
                    <Button
                      type="primary"
                      className={styles.nextBtn}
                      onClick={nextPage}
                      loading={loadingSave}
                      disabled={isDisabled}
                    >
                      Next
                      <i className="las la-long-arrow-alt-right"></i>
                    </Button>
                  )
                );
              }}
            </Form.Item>

            {settingData.isSetupCompleted ? (
              <Button
                className={styles.backBtn}
                onClick={() => {
                  setLoadingCancel(false);
                  closeModal(false);
                  navigate("/acs/setting");
                }}
                loading={loadingCancel}
              >
                Cancel
              </Button>
            ) : isBackBtn ? (
              <Button
                className={styles.backBtn}
                onClick={handleBack}
                loading={loadingCancel}
              >
                Back
              </Button>
            ) : null}
          </Space>
        </div>
      )}
      {/* <div className={styles.footer_rightSideContent}>
          <Space size={0} direction="vertical">
            <div className={styles.footer_helpBtn}>
              <Space>
                <QuestionCircleOutlined />
                <span>Need Help ?</span>
              </Space>
            </div>
            <Text className={styles.footer_text}>
              100% free setup by MLVeda
            </Text>
          </Space>
        </div> */}
    </>
  );
};

export default ModalFooter;
