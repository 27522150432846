import React from "react";

import styles from "./StepOne.module.less";

import Step1 from "../../Shared/Steps/Step1/Step1";
import Preview from "../../Shared/Preview/Preview";

// step 1 component (currencySetting component)
const StepOne = () => {
  return (
    <>
      <div className={styles.modalBody_wrapper}>
        <div className={styles.modalBody_leftSide}>
          <Step1 />
        </div>
        <div className={styles.modalBody_rightSide}>{/* <Preview /> */}</div>
      </div>
    </>
  );
};

export default StepOne;
