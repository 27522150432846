import React from "react";
import ModalComponent from "../../Shared/ModalComponent/ModalComponent";

import styles from "./LocationSetting.module.less";

import ModalHeader from "../../Shared/ModalHeader/ModalHeader";

import Step2 from "../../Shared/Steps/Step2/Step2";
import LocationPreviewImage from "../../LocationPreviewImage";

// location settings component which display after onboarding in settings section

const LocationSetting = () => {
  // console.log("CurrencySetting render");

  return (
    <>
      <ModalComponent
        header={
          <ModalHeader
            heading="Location Setting"
            sunHeading="Now, let's decide the location of the currency switcher in your store"
          />
        }
        footer={true}
      >
        <div className={styles.modalBody_wrapper}>
          <div className={styles.modalBody_leftSide}>
            <Step2 />
          </div>
          <div className={styles.modalBody_rightSide}>
            <LocationPreviewImage />
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default LocationSetting;
