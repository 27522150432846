import React, { useContext, useEffect } from "react";
import {
  SettingOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useLocation, Link } from "react-router-dom";

import styles from "./NavBarMenu.module.less";
import { AcsData } from "../../../App";

// NavBarMenu component displaying navigation menu
const NavBarMenu = ({ theme, mode, sidebar, toggle, className, ...props }) => {
  const location = useLocation();
  const menuClassName = !sidebar ? styles.settingPage_menu : "";
  const { customerType } = useContext(AcsData);

  // console.log("customerType", customerType);
  const locationUrl = location.pathname.split("/");
  // if (locationUrl.includes("setting")) {
  //   console.log("/acs/setting");
  // } else if (locationUrl.includes("subscription")) {
  //   console.log("/acs/subscription");
  // } else if (locationUrl.includes("help")) {
  //   console.log("/acs/help");
  // } else if (locationUrl.includes("dashboard")) {
  //   console.log("/acs/dashboard");
  // }

  // this function identifies the the active menu
  const getSelectedKey = () => {
    if (locationUrl.includes("setting")) {
      return ["/acs/setting"];
    } else if (locationUrl.includes("subscription")) {
      return ["/acs/subscription"];
    } else if (locationUrl.includes("help")) {
      return ["/acs/help"];
    } else if (locationUrl.includes("dashboard")) {
      return ["/acs/dashboard"];
    }
  };
  return (
    <Menu
      theme={theme}
      mode={mode}
      defaultSelectedKeys={
        // location.pathname === "/acs/dashboard"
        //   ? ["/acs/dashboard"]
        //   : [location.pathname]
        [location.pathname]
      }
      selectedKeys={getSelectedKey()}
      className={menuClassName + ` ${className || ''}`}
      {...props}
    >
      <Menu.Item key="/acs/dashboard" onClick={sidebar && toggle} title={null} icon={<HomeOutlined />}>
        DASHBOARD
        <Link to="/acs/dashboard" />
      </Menu.Item>
      <Menu.Item key="/acs/setting" onClick={sidebar && toggle} title={null} icon={<SettingOutlined />}>
        SETTINGS
        <Link to="/acs/setting" />
      </Menu.Item>
      {customerType.customerType !== "old" && (
        <Menu.Item key="/acs/subscription" onClick={sidebar && toggle} title={null} icon={<DollarOutlined />}>
          SUBSCRIPTION
          <Link to="/acs/subscription" />
        </Menu.Item>
      )}
      <Menu.Item key="/acs/help" onClick={sidebar && toggle} title={null} icon={<QuestionCircleOutlined />}>
        HELP
        <Link to="/acs/help" />
      </Menu.Item>
    </Menu>
  );
};

export default NavBarMenu;
