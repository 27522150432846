import React, { useRef, useEffect, useState, useContext } from "react";
import styles from "./PaymentCardUI.module.less";
import $ from "jquery";
import {
  Card,
  Divider,
  Row,
  Col,
  Button,
  Space,
  message,
  Typography,
  Alert,
} from "antd";

import {useLocation, useNavigate} from "react-router-dom";
import putCall from "../../../Http/putCall";
import postCall from "../../../Http/postCall";
import { AcsData, PlanData } from "../../../App";
import cardInfo from "../../../Assets/Images/card.png";
import {MONTHLY_PLAN_TYPE, premiumMonthlyPlan, premiumYearlyPlan} from "../../../utils/plansData";
import populateSubscriptionAndUpdatePlans from "../../../utils/populateSubscriptionAndUpdatePlans";
const { Text } = Typography;
const PaymentCardUI = ({
  isCardAdded,
  setIsCardAdded,
  setCardData,
  cardData,
}) => {
  const { state: locationState } = useLocation();
  const stripe = useRef(null);

  const { settingData, globalState, setSession } = useContext(AcsData);
  const { plans, setPlans } = useContext(PlanData);
  console.log("i m in loop");

  let card = useRef(null); // card red
  let setupIntent = { stripeSetupIntent: null, stripeSetupStatus: null };

  const [globalErrorMsg, setGlobalErrorMsg] = useState(" ");

  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const navigate = useNavigate();

  // handle cancel button click
  const handleCancel = () => {
    navigate(-1);
  };

  const bindStripeStripToDom = (stripeInstance) => {
    let elements = stripeInstance.elements();

    card.current = elements.create("card", {
      style: {
        base: {
          fontFamily: '"Proxima Nova Rg Regular", sans-serif',
          fontSize: "16px",
          color: "#4D5055",
        },
        invalid: {
          color: "red",
        },
      },
      hidePostalCode: true,
    });
    card.current.mount("#card-element");
  };

  // call when submit card details
  const cardFormSubmit = (e) => {
    // console.log(globalErrorMsg);
    e.preventDefault();
    setIsBtnLoading(true);
    const displayError = document.getElementById("card-number-errors");
    if (displayError) {
      displayError.textContent = "";
    }

    if (globalErrorMsg) {
      var errorElement = document.getElementById("card-number-errors");
      errorElement.textContent = globalErrorMsg;
    }

    if (Boolean($("#card-name-errors").html())) {
      $("#card-name-errors").html(" ");
    }

    if (!Boolean($("#name_on_card").val())) {
      $("#card-name-errors").html("Card holder name is required");
      setIsBtnLoading(false);
    } else {
      var tokenData = {};
      //tokenData["name"] = $("#name_on_card").val();
      tokenData.billing_details = {};
      tokenData["billing_details"]["name"] = $("#name_on_card").val();
      // console.log("tokenData.." + tokenData, card.current, stripe.current);

      stripe.current
        .createPaymentMethod("card", card.current, tokenData)
        .then((result) => {
          if (result.error) {
            // console.log("err-", result.error);
            // Inform the user if there was an error
            var errorElement = document.getElementById("card-number-errors");
            errorElement.textContent = result.error.message;
            // $("#name_on_card").val(" ");
            if (document.getElementById("payment_method_id") !== null) {
              $("#payment_method_id").val(" ");
            }
            setIsBtnLoading(false);
            //        if(this.state.apiCalled) {
            //          this.setState({ apiCalled: false });
            //        }
          } else {
            // Send the token to your server
            setIsBtnLoading(true);
            stripePaymentMethodHandler(result.paymentMethod);
          }
        })
        .catch((err) => {
          console.error(err);
          setIsBtnLoading(false);
        });
    }

    //this.confirmCardDetails();
  };

  const stripePaymentMethodHandler = (paymentMethod) => {
    // Insert the token ID into the form, so it gets submitted to the server
    var form = document.getElementById("payment-form");
    var paymentMethodInput = document.getElementById("payment_method_id");
    if (paymentMethodInput == null) {
      var hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "payment_method_id");
      hiddenInput.setAttribute("value", paymentMethod.id);
      hiddenInput.setAttribute("id", "payment_method_id");
      form.appendChild(hiddenInput);
    } else {
      paymentMethodInput.setAttribute("value", paymentMethod.id);
    }

    // console.log(hiddenInput);

    // Send paymentMethod.id to server
    confirmCardDetails();
  };

  const handleServerResponse = (response, payment_method_id) => {
    // console.log(response);
    if (response.requires_confirmation) {
      // as of now, it seems this code is not used but if error occurs
      // replace request body with: { token: payment_method_id }
      postCall("/populateSubscription", payment_method_id)
        .then((success) => {
          if (success.status === 200) {
            setCardData(success.data.cardDetails);
            message.success("Card added successfully.");
            setIsBtnLoading(false);
            setIsCardAdded(true);
          } else {
            setSession(false);
            message.error("somthing went worng add card again");
          }
        })
        .catch((error) => {
          console.error(error);
          setIsBtnLoading(false);
          message.error("somthing went worng add card again");
        });

      // call plans api
      putCall("/plans", premiumMonthlyPlan)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            setPlans(res.data.plan);
            // navigate("/");
          } else {
            setIsBtnLoading(false);
            setSession(false);
          }
        })
        .catch((err) => {
          setIsBtnLoading(false);
          // console.log(err);
        });

      // window.location.href = `https://d3aa-2405-201-2001-489a-c9eb-237e-f5dc-184e.ngrok.io/subscription`;
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      handleAction(response);
    } else if (response.processing_error) {
      handleStripeError(response);
    } else if (response.status) {
      handleStripeError(response);
    } else {
      const data = { token: payment_method_id, updateCardDetails: false, planType: plans.type };

      if (locationState?.planCycle) {
        data.planType = locationState.planCycle;
      }
      if (!locationState?.isProgrammaticNavigation && Object.keys(cardData).length > 0) {
        // not navigated programmatically, manually clicked on card details and trying to replace card
        // as the card already exists
        data.updateCardDetails = true;
      }

      populateSubscriptionAndUpdatePlans(
        data.planType,
        data.updateCardDetails,
        data.planType === MONTHLY_PLAN_TYPE ? premiumMonthlyPlan : premiumYearlyPlan,
        data.token,
      )
        .then(res => {
          if (!res) {
            setSession(false);
            return;
          }

          const { populateSubRes, plansUpdateRes } = res;
          setPlans(plansUpdateRes.data.plan);
          setCardData(populateSubRes.data.cardDetails);
          if (plans.name === "free") {
            message.success("Your plan is updated successfully");
          } else {
            message.success("Your card is updated successfully");
          }
        })
        .then(() => {
          if (locationState?.isProgrammaticNavigation) {
            navigate('/acs/subscription/plans');
          } else if (!settingData.isSetupCompleted) {
            const data = {
              stepReached: 1,
            };

            if (!settingData.isSetupCompleted && settingData.stepReached === 5) {
              navigate('/acs/setup/final-step');
              return;
            }

            // putapi call to for update settings
            return putCall("/settings", data)
              .then(res => {
                if (res.status === 200) {
                  // update globel state
                  globalState({
                    stepReached: 1,
                  });
                  navigate("/acs/setup/step-1");
                } else {
                  setSession(false);
                }

                // api aave pachhi aa delete karvu
                // globalState({
                //   stepReached: 1,
                // });
                // setLoading(false);
                // navigate("/acs/setup/step-1");
              });
          }
        })
        .then(() => {
          setIsBtnLoading(false);
          setIsCardAdded(true);
        })
        .catch(err => {
          console.error(err);
          message.error('Something went wrong!');
          setIsBtnLoading(false);
        });

      // window.location.href = `https://76b5-2405-201-2001-489a-8568-4c68-4116-abcd.ngrok.io/subscription`;
    }
  };

  const handleAction = (response) => {
    stripe.current
      .confirmCardSetup(response.payment_intent_client_secret, {
        payment_method: document.getElementById("payment_method_id").value,
      })
      .then((result) => {
        if (result.error) {
          // console.log("result.error.." + result.error.message);
          //if(this.state.apiCalled) {
          //          this.setState({ apiCalled: false });
          //        }
          $("#card-number-errors").html(
            "Authentication Failed. Click Add Card again."
          );
          setIsBtnLoading(false);
        } else {
          // The card action has been handled
          // The Intent can be confirmed again on the server
          confirmCardDetails();
        }
      })
      .catch((err) => {
        setIsBtnLoading(false);
        // console.log(err);
      });
  };

  const handleStripeError = (response) => {
    //   if(this.state.apiCalled) {
    //          this.setState({ apiCalled: false });
    //        }
    if (response.processing_error) {
      const errorContainer =
        "Your payment could not be processed due to processing error. Please try again.";

      setGlobalErrorMsg(errorContainer);
    } else {
      const chargeErrorCode = response.errorfound;
      let errorContainer;
      if (chargeErrorCode === "card_declined") {
        setIsBtnLoading(false);
        errorContainer =
          "Your payment could not be processed as your card was declined. Please try again.";
        const displayError = document.getElementById("card-number-errors");
        if (displayError) {
          displayError.textContent = errorContainer;
          // $("#name_on_card").val(" ");
          if (document.getElementById("payment_method_id") !== null) {
            $("#payment_method_id").val(" ");
          }
        }
      } else if (chargeErrorCode === "setup_intent_authentication_failure") {
        setIsBtnLoading(false);
        errorContainer =
          "Your card could not be processed due to authentication failure. Please try again.";
        const displayError = document.getElementById("card-number-errors");
        if (displayError) {
          displayError.textContent = errorContainer;
          // $("#name_on_card").val(" ");
          if (document.getElementById("payment_method_id") !== null) {
            $("#payment_method_id").val(" ");
          }
        }
      } else if (chargeErrorCode === "incorrect_cvc") {
        setIsBtnLoading(false);
        errorContainer =
          "Your payment could not be processed as your CVC code was incorrect. Please try again.";
        const displayError = document.getElementById("card-number-errors");
        if (displayError) {
          displayError.textContent = errorContainer;
          // $("#name_on_card").val(" ");
          if (document.getElementById("payment_method_id") !== null) {
            $("#payment_method_id").val(" ");
          }
        }
      } else if (chargeErrorCode === "expired_card") {
        setIsBtnLoading(false);
        errorContainer =
          "Your payment could not be processed as your card has expired. Please try again.";
        const displayError = document.getElementById("card-number-errors");
        if (displayError) {
          displayError.textContent = errorContainer;
          // $("#name_on_card").val(" ");
          if (document.getElementById("payment_method_id") !== null) {
            $("#payment_method_id").val(" ");
          }
        }
      } else {
        setIsBtnLoading(false);
        errorContainer =
          "Your payment could not be processed due to processing error. Please try again.";
        // $("#name_on_card").val(" ");
        if (document.getElementById("payment_method_id") !== null) {
          $("#payment_method_id").val(" ");
        }
        // setGlobalErrorMsg(errorContainer);
      }
      setGlobalErrorMsg(errorContainer);
    }
  };

  const confirmCardDetails = () => {
    const mldata = {
      payment_method_id: document.getElementById("payment_method_id").value,
      stripeSetupIntent: setupIntent.stripeSetupIntent,
      stripeSetupStatus: setupIntent.stripeSetupStatus,
    };

    postCall("/cardDetails", mldata)
      .then((cardDetails) => {
        if (cardDetails.status === 200) {
          setupIntent.stripeSetupIntent = cardDetails.data.stripeSetupIntent;
          setupIntent.stripeSetupStatus = cardDetails.data.stripeSetupStatus;
          handleServerResponse(cardDetails.data, mldata.payment_method_id);
        } else {
          if (
            cardDetails.error.response &&
            cardDetails.error.response.status &&
            cardDetails.error.response.status === 401 &&
            cardDetails.error.response.data &&
            cardDetails.error.response.data.error &&
            cardDetails.error.response.data.error === "sessiontimeout"
          ) {
            this.props.routeProps.history.push("/timeout"); //to be checked later
          } else {
            setIsBtnLoading(false);
            setSession(false);
            // message.error("something went wrong.");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setIsBtnLoading(false);
        // setSession(false);
        message.error("something went wrong.");
      });
  };

  useEffect(() => {
    if (!isCardAdded) {
      if (window.Stripe) {
        if (stripe.current === null) {
          // key for Testing :- pk_test_mbReAVVJ8iz4tFzak40sQtyn
          stripe.current = window.Stripe("pk_live_nnlIq7SZy196wnw0DoROcOPJ");
        }
      } else {
        document.querySelector("#stripe-js").addEventListener("load", () => {
          if (stripe.current === null) {
            // key for Testing :- pk_test_mbReAVVJ8iz4tFzak40sQtyn
            stripe.current = window.Stripe("pk_live_nnlIq7SZy196wnw0DoROcOPJ");
          }
        });
      }
    } else {
      stripe.current = null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardAdded]);

  useEffect(() => {
    // console.log("card added ");
    if (!isCardAdded) {
      if (stripe.current) {
        bindStripeStripToDom(stripe.current);
      } else {
        // console.log("error while calling bindStripeStripToDom()");
      }
    } else {
      stripe.current = null;
    }

    return () => {
      // console.log("unmount card element");
      if (card.current) {
        card.current.unmount();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardAdded]);

  useEffect(() => {
    if (card.current) {
      card.current.on("change", function (event) {
        setGlobalErrorMsg(" ");
        var displayError = document.getElementById("card-number-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.cardPayment_wrapper}>
        {/* {loading ? (
          <Spin />
        ) : */}
        {!isCardAdded ? (
          <>
            <Alert
              message="Please enter card details if you want to start premium plan."
              type="info"
              showIcon
              style={{ marginBottom: "10px" }}
            />
            <Card>
              <form onSubmit={cardFormSubmit} id="payment-form">
                <div className={styles.cardPayment_form_wrappper}>
                  <div className={styles.cardPayment_fields_wrapper}>
                    <div className={styles.cardPayment_holderName}>
                      <label className={styles.cardPayment_form_label}>
                        Card holder
                      </label>
                      <input
                        style={{
                          width: "100%",
                          padding: "9px",
                          border: "1px solid #d9d9d9",
                          outline: "none",
                        }}
                        type="text"
                        placeholder="Name and Surname"
                        autoComplete="off"
                        id="name_on_card"
                        onChange={(e) => {
                          if (e.target.value === "") {
                            $("#card-name-errors").html(
                              "Card holder name is required"
                            );
                          }
                          if (e.target.value) {
                            $("#card-name-errors").html(" ");
                          }
                        }}
                      ></input>
                      <div
                        id="card-name-errors"
                        className="card-errors"
                        role="alert"
                        style={{
                          marginTop: 10,
                          fontSize: "16px",
                          color: "red",
                        }}
                      ></div>
                    </div>
                    <div className={styles.cardPayment_cardDetails}>
                      <label className={styles.cardPayment_form_label}>
                        Credit or debit card
                      </label>

                      <div
                        id="card-element"
                        style={{
                          border: "1px solid #d9d9d9",
                          padding: "11px 11px",
                        }}
                      ></div>

                      <div
                        id="card-number-errors"
                        className="card-errors"
                        role="alert"
                        style={{
                          marginTop: 10,
                          fontSize: "16px",
                          color: "red",
                        }}
                      ></div>
                    </div>
                  </div>
                  <Divider
                    style={{
                      margin: "0px",
                      borderTop: "1px solid #d0d0d0",
                    }}
                  />
                  <div className={styles.cardPayment_btnWrapper}>
                    {/* <Space size={20}> */}
                    <div className={styles.cancel_btn}>
                      <Button size="large" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </div>
                    <div className={styles.addCard_btn}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={globalErrorMsg !== " " || isBtnLoading}
                        loading={isBtnLoading}
                      >
                        {Object.keys(cardData).length > 0 ? 'Update card and Proceed' : 'Add card and Proceed'}
                      </Button>
                    </div>
                    {/* </Space> */}
                  </div>
                </div>
              </form>
            </Card>
          </>
        ) : (
          <div className={styles.cardDetails_wrapper}>
            <Card className={styles.cardDetails}>
              <Row justify="space-between" wrap align="middle">
                <Col sm={4} xs={24} md={4} lg={4} xl={4} xxl={4} style={{ textAlign: 'center' }}>
                  <img
                    src={cardInfo}
                    style={{ height: "100px", width: "100px" }}
                    alt="cardDetails"
                  />
                </Col>
                <Col sm={4} xs={24} md={4} lg={4} xl={4} xxl={4} style={{ textAlign: 'center' }}>
                  <div style={{ color: "#4d5055", textAlign: 'start', display: 'inline-block' }}>
                    Manage How to pay your MLveda charges
                  </div>
                </Col>
                <Col sm={4} xs={24} md={4} lg={4} xl={4} xxl={4} style={{ textAlign: 'center' }}>
                  <Space direction="vertical">
                    <Text style={{ color: "#4d5055" }} strong>
                      VISA
                    </Text>
                    <Text style={{ color: "#4d5055" }}>
                      ending in
                      <span style={{ fontWeight: "bold", marginLeft: "3px" }}>
                        {cardData.last4}
                      </span>
                    </Text>
                    <div style={{ borderBottom: "1px solid #4d5055" }}></div>
                    <Button
                      type="link"
                      style={{ margin: 0, padding: 0 }}
                      onClick={() => {
                        setIsCardAdded(false);
                      }}
                      // loading={true}
                    >
                      Replace Card
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentCardUI;
