import React, { useState } from "react";
import { Form, Select, Popover, Input } from "antd";
import styles from "./CustomPopover.module.less";
import CustomChromePicker from "../CustomChromePicker/CustomChromePicker";
const { Option } = Select;

const CustomSelectbox = (props) => {
  // console.log("custom popover box", props);
  const [borderColorPopover, setBorderColorPopover] = useState(false);
  const { value } = props;

  // console.log(value);
  return (
    <>
      <Popover
        content={
          <Form.Item name="borderLineColor">
            <CustomChromePicker />
          </Form.Item>
        }
        trigger="click"
        visible={value && borderColorPopover}
        onVisibleChange={() => setBorderColorPopover(!borderColorPopover)}
      >
        <Form.Item dependencies={["borderLineColor"]}>
          {({ getFieldValue }) => (
            <Input
              // name="textColor"
              disabled={!value && true}
              size="large"
              placeholder="select color"
              value={getFieldValue("borderLineColor")}
              suffix={
                <span
                  className={styles.color_demo}
                  style={{ background: getFieldValue("borderLineColor") }}
                ></span>
              }
            />
          )}
        </Form.Item>
      </Popover>
    </>
  );
};

export default CustomSelectbox;
