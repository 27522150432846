import classNames from 'classnames';
import classes from './BillingFrequencySwitch.module.less';
import {MONTHLY_PLAN_TYPE, YEARLY_PLAN_TYPE} from "../../../utils/plansData";

function RadioInput({inputValue, value}) {
  return (
    <input value={inputValue} type='radio' checked={value === inputValue} name='billing-frequency-slider'/>
  )
}

export default function BillingFrequencySwitch({value, onChange}) {
  return (
    <div className={classes.container}>
      <div role='radiogroup' className={classes.radioGroupContainer}>
        <div
          className={classNames(classes.switchHighlighter, value === MONTHLY_PLAN_TYPE && classes.monthlySwitchHighlighter, value === YEARLY_PLAN_TYPE && classes.yearlySwitchHighlighter)}/>
        <label className={classNames(classes.label, classes.yearlyLabel)}>
          <RadioInput inputValue={YEARLY_PLAN_TYPE} value={value}/>
          <p>Yearly</p>
        </label>
        <label className={classNames(classes.label, classes.monthlyLabel)}>
          <RadioInput inputValue={MONTHLY_PLAN_TYPE} value={value}/>
          <p>Monthly</p>
        </label>
        <button
          className={classes.switch}
          onClick={() => onChange(value === MONTHLY_PLAN_TYPE ? YEARLY_PLAN_TYPE : MONTHLY_PLAN_TYPE)}
        />
      </div>
    </div>
  )
}